export const BOOKING_TIME_CONFLICT = 'meeting booked from underneath';
export const INVALID_DOMAIN_FOR_LINK = 'INVALID_DOMAIN_FOR_LINK';
export const NOT_FOUND = 'Link not found';
export const USER_NOT_FOUND = 'User not found';
export const MEETING_PREVIOUSLY_CANCELLED = 'Could not complete reschedule request, meeting was previously cancelled.';
export const REBOOK_CONFLICT = 'Could not complete reschedule request, event times conflict with existing event.';
export const EXCHANGE_CANCELLATION = "Unable to modify event. Calendar provider not supported 'EXCHANGE'";
export const MEETING_TEMPORARILY_UNAVAILABLE = 'MeetingsBookingSchedulingError.MEETING_LINK_TEMPORARILY_UNAVAILABLE';
export const CalendarErrorStatuses = ['UNKNOWN_ERROR', 'AUTH_ERROR'];
export const SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE';
export const SCHEDULING_ERRORS = {
  SERVICE_OUTAGE: 'SCHEDULING_ERROR_SERVICE_OUTAGE',
  VCE_LINK_GENERATION: 'SCHEDULING_ERROR_VCE_LINK_GENERATION'
};