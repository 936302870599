import I18n from 'I18n';
import { Record as ImmutableRecord, List, Range } from 'immutable';
const defaults = {
  start: 0,
  end: Infinity
};
function convertToMinutes(timestamp) {
  return Math.floor(timestamp / 60000);
}
function convertToMilliseconds(timestampMinutes) {
  return timestampMinutes * 60000;
}
export default class TimeRange extends ImmutableRecord(defaults) {
  static from(json) {
    return new TimeRange(json);
  }
  toBlocks(duration) {
    return Range(0, Math.floor(this.duration() / duration)).map(i => TimeRange.from({
      start: this.start + i * duration,
      end: this.start + (i + 1) * duration
    })).toList();
  }
  toMinutes() {
    if (!this.isInMinutes()) {
      return TimeRange.from({
        start: convertToMinutes(this.start),
        end: convertToMinutes(this.end)
      });
    }
    return this;
  }
  toMilliseconds() {
    if (!this.isInMilliseconds()) {
      return TimeRange.from({
        start: convertToMilliseconds(this.start),
        end: convertToMilliseconds(this.end)
      });
    }
    return this;
  }
  isInMilliseconds() {
    const FIFTEEN_MINUTE_DURATION = I18n.moment.duration({
      minutes: 15
    }).asMilliseconds();
    return Math.floor(this.end / FIFTEEN_MINUTE_DURATION) >= 1;
  }
  isInMinutes() {
    const DAY_DURATION_MINUTES = I18n.moment.duration({
      hours: 24
    }).asMinutes();
    return Math.floor(this.end / DAY_DURATION_MINUTES) <= 1;
  }
  isValidTimeRange() {
    return List([typeof this.start, typeof this.end]).every(t => t === 'number');
  }
  duration() {
    return this.end - this.start;
  }
  contain(time) {
    return Math.max(Math.min(time, this.end - 1), this.start);
  }
  timeToOffsetFloat(time) {
    return (time - this.start) / this.duration();
  }
  isEqual(dtr) {
    return this.start === dtr.start && this.end === dtr.end;
  }
  hasIntersection(dtr) {
    return this.start < dtr.end && this.end > dtr.start;
  }
  contains(time) {
    return this.start <= time && this.end > time;
  }
  pushBack(duration) {
    return TimeRange.from({
      start: this.start - duration,
      end: this.end - duration
    });
  }
  subtractStart(duration) {
    return TimeRange.from({
      start: this.start - duration,
      end: this.end
    });
  }
  subtractEnd(duration) {
    return TimeRange.from({
      start: this.start,
      end: this.end - duration
    });
  }
  union(dtr) {
    if (!this.hasIntersection(dtr)) return List([this, dtr]);
    return List([TimeRange.from({
      start: Math.min(this.start, dtr.start),
      end: Math.max(this.end, dtr.end)
    })]);
  }
  intersect(dtr) {
    if (!this.hasIntersection(dtr)) return null;
    return TimeRange.from({
      start: Math.max(this.start, dtr.start),
      end: Math.min(this.end, dtr.end)
    });
  }
  clipEnd(edge) {
    if (edge < this.start) return null;
    return TimeRange.from({
      start: Math.min(edge, this.start),
      end: Math.min(edge, this.end)
    });
  }
  clipStart(edge) {
    if (edge > this.end) return null;
    return TimeRange.from({
      start: Math.max(edge, this.start),
      end: Math.max(edge, this.end)
    });
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}