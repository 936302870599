"use es6";

/* Core */
export { ApolloClient, mergeOptions } from "apollo-stack-hubspot/@apollo/client/core/ApolloClient";
export { ObservableQuery } from "apollo-stack-hubspot/@apollo/client/core/ObservableQuery";
export { NetworkStatus, isNetworkRequestSettled } from "apollo-stack-hubspot/@apollo/client/core/networkStatus";
export * from "apollo-stack-hubspot/@apollo/client/core/types";
export { isApolloError, ApolloError } from "apollo-stack-hubspot/@apollo/client/errors/index";
export { Cache, ApolloCache, InMemoryCache, MissingFieldError, defaultDataIdFromObject, makeVar } from "apollo-stack-hubspot/@apollo/client/cache/index";
export * from "apollo-stack-hubspot/@apollo/client/cache/inmemory/types";
/* Link */
export * from "apollo-stack-hubspot/@apollo/client/link/core/index";
export * from "apollo-stack-hubspot/@apollo/client/link/http/index";
export { fromError, toPromise, fromPromise, throwServerError } from "apollo-stack-hubspot/@apollo/client/link/utils/index";
export { DocumentTransform, Observable, isReference, makeReference } from "apollo-stack-hubspot/@apollo/client/utilities/index";
/* Supporting */
// The verbosity of invariant.{log,warn,error} can be controlled globally
// (for anyone using the same ts-invariant package) by passing "log",
// "warn", "error", or "silent" to setVerbosity ("log" is the default).
// Note that all invariant.* logging is hidden in production.
import { setVerbosity } from "apollo-stack-hubspot/internal/ts-invariant/lib/invariant";
export { setVerbosity as setLogVerbosity };
setVerbosity(process.env.NODE_ENV !== "production" ? "log" : "silent");
// Note that importing `gql` by itself, then destructuring
// additional properties separately before exporting, is intentional.
// Due to the way the `graphql-tag` library is setup, certain bundlers
// can't find the properties added to the exported `gql` function without
// additional guidance (e.g. Rollup - see
// https://rollupjs.org/guide/en/#error-name-is-not-exported-by-module).
// Instead of having people that are using bundlers with `@apollo/client` add
// extra bundler config to help `graphql-tag` exports be found (which would be
// awkward since they aren't importing `graphql-tag` themselves), this
// workaround of pulling the extra properties off the `gql` function,
// then re-exporting them separately, helps keeps bundlers happy without any
// additional config changes.
export { gql, resetCaches, disableFragmentWarnings, enableExperimentalFragmentVariables, disableExperimentalFragmentVariables } from "apollo-stack-hubspot/internal/graphql-tag/lib/index";