import { Record, List } from 'immutable';
import FormPrefills from 'MeetingsBase/models/FormPrefills';
import LinkAvailability from 'MeetingsBase/models/LinkAvailability';
import LinkCustomParams from 'MeetingsBase/models/LinkCustomParams';
import UserBusyTimes from 'MeetingsBase/models/UserBusyTimes';
const defaults = {
  allUsersBusyTimes: List(),
  bufferTime: 0,
  contentTracking: null,
  customParams: new LinkCustomParams(),
  formGuid: null,
  formPrefills: new FormPrefills(),
  inactive: false,
  linkAvailability: new LinkAvailability(),
  linkId: null,
  linkType: null,
  offline: false,
  openGates: List(),
  ownerPrioritized: null,
  portalId: null,
  userId: null
};
export default class PublicAvailabilityInfo extends Record(defaults) {
  static from(json) {
    const customParams = LinkCustomParams.from(json.customParams);
    const allUsersBusyTimes = List(json.allUsersBusyTimes.map(userBusyTimes => UserBusyTimes.from(userBusyTimes)));
    const formPrefills = FormPrefills.from(json.formPrefills, customParams.get('formFields'));
    const linkAvailability = LinkAvailability.from(json.linkAvailability);
    return new PublicAvailabilityInfo({
      allUsersBusyTimes,
      bufferTime: customParams.meetingBufferTime,
      linkId: json.linkId,
      linkType: json.linkType,
      userId: json.userId,
      customParams,
      offline: json.isOffline,
      openGates: List(json.openGates),
      ownerPrioritized: json.customParams.ownerPrioritized,
      inactive: json.inactive,
      formGuid: json.formGuid,
      contentTracking: json.contentTracking,
      formPrefills,
      linkAvailability,
      portalId: json.portalId
    });
  }
  convertToJSON() {
    const {
      allUsersBusyTimes,
      bufferTime,
      contentTracking,
      customParams,
      formGuid,
      formPrefills,
      inactive,
      linkAvailability,
      linkId,
      linkType,
      offline,
      openGates,
      ownerPrioritized,
      portalId,
      userId
    } = this;
    return {
      allUsersBusyTimes: allUsersBusyTimes.toJS(),
      bufferTime,
      contentTracking,
      customParams: customParams.convertToJSON(),
      formGuid,
      formPrefills: formPrefills.convertToJSON(),
      inactive,
      linkAvailability: linkAvailability.convertToJSON(),
      linkId,
      linkType,
      offline,
      openGates: openGates.toJS(),
      ownerPrioritized,
      portalId,
      userId
    };
  }

  /**
   * @deprecated this doesn't seem to be used anywhere
   */
  updateBusyTime(jsonUserBusyTimes) {
    const updatedUserBusyTimes = UserBusyTimes.from(jsonUserBusyTimes);
    return this.update('allUsersBusyTimes', allUsersBusyTimes => allUsersBusyTimes.map(userBusyTimes => {
      if (userBusyTimes.id === updatedUserBusyTimes.id) {
        return updatedUserBusyTimes;
      }
      return userBusyTimes;
    }).toList());
  }
  updateUserBusyTimes(allUserBusyTimesList) {
    const existingAllUsersBusyTimes = this.get('allUsersBusyTimes').toArray().map(userBusyTimesRecord => userBusyTimesRecord.convertToJSON());
    existingAllUsersBusyTimes.forEach(userBusyTimes => {
      const incomingUserBusyTimes = allUserBusyTimesList.find(incomingBusyTimes => incomingBusyTimes.id === userBusyTimes.id);
      if (incomingUserBusyTimes) {
        incomingUserBusyTimes.busyTimes.forEach(incomingUserBusyTime => {
          const duplicate = userBusyTimes.busyTimes.find(busyTime => busyTime.start === incomingUserBusyTime.start && busyTime.end === incomingUserBusyTime.end);
          if (!duplicate) {
            userBusyTimes.busyTimes.push(incomingUserBusyTime);
          }
        });
      }
    });
    const updatedAllUsersBusyTimes = List(existingAllUsersBusyTimes.map(userBusyTimes => {
      // @ts-expect-error mismatched types
      userBusyTimes.isOffline = userBusyTimes.offline;
      // @ts-expect-error mismatched types
      return UserBusyTimes.from(userBusyTimes);
    }));
    return this.set('allUsersBusyTimes', updatedAllUsersBusyTimes);
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}