module.exports = {
  "meetingsLibLoaded": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "referrer": [
        "forms"
      ],
      "screen": [
        "public",
        "embedded"
      ]
    },
    "namespace": "meetings"
  },
  "meetingsLibViewScreen": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": [
        "public",
        "embedded"
      ],
      "stage": "string"
    },
    "namespace": "meetings"
  },
  "meetingsLibClickPromoLink": {
    "name": "click viral link",
    "class": "interaction",
    "properties": {
      "location": {
        "type": "string",
        "isOptional": true
      },
      "page": "string"
    },
    "namespace": "meetings"
  },
  "meetingsLibSetProperty": {
    "name": "Set meeting property",
    "class": "interaction",
    "properties": {
      "property": "string",
      "value": "string"
    },
    "namespace": "meetings"
  },
  "meetingsLibTroubleshoot": {
    "name": "troubleshoot",
    "class": "interaction",
    "properties": {
      "action": "string"
    },
    "namespace": "meetings"
  },
  "meetingsLibUsage": {
    "name": "scheduling page usage",
    "class": "usage",
    "properties": {
      "action": [
        "booked meeting"
      ],
      "referrer": [
        "forms",
        "none"
      ]
    },
    "namespace": "meetings"
  },
  "meetingsLibViralityExperiment": {
    "name": "meetingsviralityexperiment",
    "class": "interaction",
    "properties": {
      "action": [
        "view",
        "click",
        "error"
      ],
      "variation": [
        "control",
        "variant",
        "default",
        "error",
        "timeout"
      ]
    },
    "namespace": "meetings"
  },
  "meetingsViralityPageView": {
    "name": "meetingsviralityexperiment",
    "class": "interaction",
    "properties": {
      "action": "string"
    },
    "namespace": "meetings"
  },
  "meetingEventInteraction": {
    "name": "Meeting event interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked reschedule",
        "clicked cancel",
        "viewed reschedule",
        "viewed cancel"
      ],
      "source": {
        "type": "string"
      }
    },
    "namespace": "meetings"
  },
  "meetingBookingTimeConflict": {
    "name": "Scheduling interaction",
    "class": "interaction",
    "properties": {
      "portal_age_months": {
        "type": "string",
        "isOptional": true
      },
      "portal_age_days": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "user_age_months": {
        "type": "string",
        "isOptional": true
      },
      "chrome_extension_installed": {
        "type": "string",
        "isOptional": true
      },
      "user_age_days": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "viewed-meeting-conflict-error"
      ],
      "project": [
        "MeetingsLib"
      ]
    },
    "namespace": "meetings"
  },
  "paymentBuyer": {
    "name": "Payment Buyer Interaction",
    "class": "usage",
    "properties": {
      "step": [
        "selected time",
        "completed property form",
        "completed legal consent form",
        "payment processing",
        "payment successful",
        "payment failed",
        "paid meeting booked time unavailable",
        "book paid meeting success",
        "book paid meeting failure"
      ]
    },
    "namespace": "meetings"
  },
  "meetingRotations": {
    "name": "Meetings Rotations",
    "class": "usage",
    "properties": {
      "action": [
        "booked meeting for suggested host",
        "booked meeting for non-suggested host",
        "booked meeting for self through rotation",
        "booked meeting for others through rotation"
      ],
      "source": {
        "type": "string"
      }
    },
    "namespace": "meetings"
  }
};