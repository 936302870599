'use es6';

import get from 'transmute/get';
import { createSelector } from 'reselect';
import { getLegalConsentEnabled, getIsLegitimateInterest } from './BookInfoSelectors';
export const getStagedLegalConsentResponses = get('stagedLegalConsentResponses');
export const getLegalConsentResponses = get('legalConsentResponses');
export const getLegitimateInterestConfirmed = get('legitimateInterestConfirmed');
const allRequiredResponsesCollected = consentResponses => {
  if (!consentResponses) {
    return false;
  }
  return consentResponses.filter(response => response.required).every(requiredResponse => requiredResponse.consented);
};
export const getAllStagedRequiredConsentCollected = createSelector([getStagedLegalConsentResponses], allRequiredResponsesCollected);
export const getAllRequiredConsentCollected = createSelector([getLegalConsentEnabled, getIsLegitimateInterest, getLegitimateInterestConfirmed, getLegalConsentResponses], (legalConsentEnabled, isLegitimateInterest, legitimateInterestConfirmed, consentResponses) => !legalConsentEnabled || (isLegitimateInterest ? legitimateInterestConfirmed : allRequiredResponsesCollected(consentResponses)));