import { Record } from 'immutable';
import get from 'transmute/get';
import UserProfile from 'MeetingsBase/models/UserProfile';
const defaults = {
  calendarProvider: 'GOOGLE',
  id: null,
  isSalesStarter: false,
  portalId: null,
  userId: null,
  userProfile: new UserProfile()
};
export default class PublicMeetingsUser extends Record(defaults) {
  static from(json) {
    return new PublicMeetingsUser(Object.assign({}, json, {
      isSalesStarter: json.hasSalesStarterFeatures || json.isSalesStarter || json.salesPro || false,
      userProfile: new UserProfile(json.userProfile)
    }));
  }
  static fromBookInfo(bookInfo) {
    return get('meetingsUser', bookInfo.allUsersBusyTimes.find(userBusyTimes => {
      if (userBusyTimes.meetingsUser && userBusyTimes.meetingsUser.userId) {
        return userBusyTimes.meetingsUser.userId === bookInfo.userId;
      }
      return false;
    }));
  }
  convertToJSON() {
    const {
      calendarProvider,
      id,
      isSalesStarter,
      portalId,
      userId,
      userProfile
    } = this;
    return {
      calendarProvider,
      id,
      isSalesStarter,
      portalId,
      userId,
      userProfile: userProfile.toJSON()
    };
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}