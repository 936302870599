import I18n from 'I18n';
import { List } from 'immutable';
import TimezoneGroups from '../lists/TimezoneGroups';
import TimezoneBlacklist from '../lists/TimezoneBlacklist';
import TimezoneSpecialHandlingGroup from '../lists/TimezoneSpecialHandlingGroup';
import TimezoneGroup from '../records/TimezoneGroup';
import Timezone from '../records/Timezone';
import join from '../utils/join';
const MAX_CITIES_IN_GROUP_NAME = 4;
export default (timezoneAliases => {
  const currentMoment = I18n.moment().valueOf();
  const tzNames = I18n.moment.tz.names();
  const timezonesByZoneRegion = tzNames.filter(name => name.search(/GMT/g) < 0 && name.search(/Etc/g) < 0 && name.search(/\//g) > -1 && !TimezoneBlacklist.includes(name)).reduce((byZoneRegion, name) => {
    const zoneRegion = name.split('/')[0];
    if (!byZoneRegion[zoneRegion]) byZoneRegion[zoneRegion] = [];
    byZoneRegion[zoneRegion].push(name);
    return byZoneRegion;
  }, {});
  const timezoneGroups = Object.keys(timezonesByZoneRegion).map(zoneRegion => {
    const timezonesForZoneRegion = timezonesByZoneRegion[zoneRegion];
    const sortedTimezones = timezonesForZoneRegion.reduce((byMatchingZoneType, timezoneName) => {
      const momentTimezone = I18n.moment.tz.zone(timezoneName);
      if (!momentTimezone) {
        return byMatchingZoneType;
      }
      const futureUntils = momentTimezone.untils.filter(until => until > currentMoment);
      const futureOffsets = momentTimezone.offsets.slice(-futureUntils.length);
      const key = futureUntils.concat(futureOffsets).join('');
      if (!byMatchingZoneType[key]) byMatchingZoneType[key] = [];
      if (TimezoneSpecialHandlingGroup.has(timezoneName)) {
        const specialGroup = TimezoneSpecialHandlingGroup.get(timezoneName);
        if (byMatchingZoneType[specialGroup]) {
          byMatchingZoneType[specialGroup].push(timezoneName);
        } else {
          byMatchingZoneType[specialGroup] = [timezoneName];
        }
        return byMatchingZoneType;
      }
      byMatchingZoneType[key].push(timezoneName);
      return byMatchingZoneType;
    }, {});
    const timezones = Object.values(sortedTimezones).map(momentTimezones => {
      const aliasWithTimezoneInGroup = alias => alias.timezones.some(timezone => momentTimezones.indexOf(timezone) !== -1);
      const alias = timezoneAliases.find(aliasWithTimezoneInGroup);
      const offset = I18n.moment.tz(momentTimezones[0]).format('Z');
      if (alias) {
        return new Timezone({
          offset,
          momentTimezones: List(momentTimezones),
          name: alias.name
        });
      }
      const cityNames = momentTimezones.map(timezone => {
        const cityName = timezone.split('/').pop();
        if (!cityName) {
          return '';
        }
        return cityName.toLowerCase();
      });
      const translatedCityNames = cityNames.map(name => I18n.text(`timezone_utils.cities.${name}`));
      const name = join(translatedCityNames.slice(0, MAX_CITIES_IN_GROUP_NAME));
      return new Timezone({
        offset,
        momentTimezones: List(momentTimezones),
        name
      });
    });
    return new TimezoneGroup({
      groupName: I18n.text(`timezone_utils.regions.${zoneRegion.toLowerCase()}`),
      timezones: List(timezones)
    });
  });
  return new TimezoneGroups(timezoneGroups);
});