import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
import { List } from 'immutable';
import get from 'transmute/get';
import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
import * as TimeRangeListUtils from 'MeetingsBase/utils/TimeRangeListUtils';
import TimeRange from 'MeetingsBase/models/TimeRange';
import { LINK_TYPES, MEETING_TYPES, LINK_TYPE_PARAMS, DISPLAY_AVATAR_OPTION } from 'MeetingsBase/constants/enums';
import { wrongHubSpotDomain, wrongHubSpotQADomain, hubSpotDomain, hubSpotQADomain } from 'MeetingsLib/constants/paths';
import { getUrlDomain, getHubSpotIdentityInfo } from 'MeetingsLib/utils/analytics';
import { sanitizeString } from 'MeetingsLib/utils/sanitize';
import { hubletApi } from 'hub-http/middlewares/core';
import { getFullUrl, getFullUrlWithCurrentSubDomain } from 'hubspot-url-utils';
import Url from 'urlinator/Url';
import { MEETING_SOURCE_CALENDAR_EVENT, MEETING_SOURCE_EMAIL, LEGACY_MEETING_SOURCE_PARAM, MEETING_SOURCE_PARAM } from '../constants/tracking';
export const hubletAwareApi = hubletApi('api', 'hubspot', window.hubspot.meetingsHubletOverride);
export function urlWithCrossDomainTracking(url) {
  const identityInfo = getHubSpotIdentityInfo();
  const firstSeparator = url.indexOf('?') !== -1 ? '&' : '?';
  return Object.keys(identityInfo).reduce((accUrl, param, idx) => {
    const separator = idx ? '&' : firstSeparator;
    if (identityInfo[param] !== undefined) {
      return `${accUrl}${separator}${param}=${identityInfo[param]}`;
    }
    return accUrl;
  }, url);
}
export function redirectToUrl(url, embedded = false) {
  // Support cross domain tracking https://issues.hubspotcentral.com/browse/SMMC-1524
  const sourceDomain = getUrlDomain(window.location.href);
  const redirectDomain = getUrlDomain(url);
  const redirectUrl = sourceDomain === redirectDomain ? url : urlWithCrossDomainTracking(url);
  if (embedded) {
    window.top.location.href = redirectUrl;
  } else {
    window.location.assign(redirectUrl);
  }
}
export function redirectToDomain(domain) {
  window.location.host = domain;
}
export function maybeRedirectToHubSpotDomain(domain) {
  if (domain === wrongHubSpotDomain) {
    redirectToDomain(hubSpotDomain);
  } else if (domain === wrongHubSpotQADomain) {
    redirectToDomain(hubSpotQADomain);
  }
}
export function redirectToMigratedPortal(baseUrl, meetingSlug, {
  searchParams
}) {
  const migratedUrl = `${baseUrl}/${meetingSlug}${searchParams}`;
  window.location.replace(migratedUrl);
}
export const getDomain = email => {
  return email.replace(/.*@/, '');
};
export function getAdminDomain() {
  return getFullUrl('app', {
    hubletOverride: window.hubspot.meetingsHubletOverride
  });
}
export function getLongDisplayName(profile) {
  if (profile.firstName && profile.lastName) {
    return formatName({
      firstName: profile.firstName,
      lastName: profile.lastName
    });
  }
  return profile.fullName || profile.firstName || profile.name || profile.email;
}
export function getIsRoundRobin(linkType) {
  return linkType === LINK_TYPES.get(MEETING_TYPES.ROUND_ROBIN);
}
export function getLinkEditParam(linkType) {
  const isTeamLink = getIsRoundRobin(linkType) || linkType === LINK_TYPES.get(MEETING_TYPES.GROUP);
  return isTeamLink ? LINK_TYPE_PARAMS.get('team') : LINK_TYPE_PARAMS.get(MEETING_TYPES.PERSONAL);
}
export function getHeaderType(isRoundRobin) {
  return isRoundRobin ? 'roundRobinHeader' : 'header';
}
export function getHeaderOptions(isRoundRobin, companyName, profile) {
  return isRoundRobin ? {
    companyName: companyName || getDomain(profile.email)
  } : {
    fullName: I18n.SafeString(sanitizeString(getLongDisplayName(profile)))
  };
}
export function setDocumentTitle(headline, linkType, companyName, profile) {
  const isRoundRobin = getIsRoundRobin(linkType);
  const headerType = getHeaderType(isRoundRobin);
  const options = getHeaderOptions(isRoundRobin, companyName, profile);
  const profileHeader = I18n.text(`prebook.${headerType}.online`, options);
  document.title = headline || profileHeader;
}
function getRandomIndex(length) {
  return Math.floor(Math.random() * length);
}
export function isWhitespace(value) {
  return typeof value === 'string' && value.trim().length <= 0;
}
export function isEmpty(value) {
  return !value || value.trim() === '';
}
export function formatCustomFormFields(formFields) {
  return formFields.reduce((acc, fieldAnswer, fieldName) => {
    if (fieldAnswer === null) {
      // if unrequired field is left blank, don't send anything
      return acc;
    }
    const isDateField = fieldAnswer.year && fieldAnswer.month && fieldAnswer.date;
    const isListField = Array.isArray(fieldAnswer);
    if (isDateField) {
      fieldAnswer = I18n.moment.utc([fieldAnswer.year, fieldAnswer.month, fieldAnswer.date]).valueOf().toString();
    }
    if (isListField) {
      fieldAnswer = fieldAnswer.join(';');
    }
    return acc.push({
      name: fieldName,
      value: fieldAnswer
    });
  }, List());
}
export function getOnlineOfflineBookableUsers(bufferTime, meeting, allUsersBusyTimes) {
  const onlineOffline = allUsersBusyTimes.map(userBusyTimes => userBusyTimes.update('busyTimes', busyTimes => TimeRangeListUtils.addBufferTime(busyTimes, bufferTime))).filter(paddedUserBusyTimes => paddedUserBusyTimes.get('busyTimes').every(paddedBusyTime => !paddedBusyTime.hasIntersection(meeting))).groupBy(availableUsers => availableUsers.get('offline'));
  return {
    onlineBookableUsers: onlineOffline.get(false),
    offlineBookableUsers: onlineOffline.get(true)
  };
}
export function getRoundRobinUserIdToBook(event, allUsersBusyTimes, bufferTime) {
  const {
    dateTime,
    duration
  } = event;
  // @ts-expect-error mismatched types
  const meeting = TimeRange.from({
    start: dateTime,
    end: dateTime + duration
  });
  const {
    onlineBookableUsers,
    offlineBookableUsers
  } = getOnlineOfflineBookableUsers(bufferTime, meeting, allUsersBusyTimes);
  if (onlineBookableUsers && onlineBookableUsers.size) {
    const chosenUserId = onlineBookableUsers.get(getRandomIndex(onlineBookableUsers.size)).get('id');
    const allUserIds = onlineBookableUsers.map(user => user.get('id'));
    return {
      chosenUserId,
      allUserIds
    };
  } else if (offlineBookableUsers && offlineBookableUsers.size) {
    const chosenUserId = offlineBookableUsers.get(getRandomIndex(offlineBookableUsers.size)).get('id');
    return {
      chosenUserId,
      allUserIds: null
    };
  }
  Raven.captureMessage('No users bookable on round robin link', {
    extra: {
      allUsersBusyTimes,
      bufferTime,
      meeting
    }
  });
  return {
    chosenUserId: null,
    allUserIds: null
  };
}
export function shouldIncludeDeleted(params) {
  return get('includeDeleted', params) || 'false';
}
export function getMeetingSourceFromLocation() {
  const url = new Url(window.location);
  const meetingSource = url.paramValue(MEETING_SOURCE_PARAM) || url.paramValue(LEGACY_MEETING_SOURCE_PARAM);
  if (meetingSource === '1') {
    return MEETING_SOURCE_CALENDAR_EVENT;
  } else if (meetingSource === '2') {
    return MEETING_SOURCE_EMAIL;
  }
  return meetingSource;
}
export function getDisplayAvatarOption(displayAvatarOption, customAvatar, companyAvatar, isPersonal) {
  switch (displayAvatarOption) {
    case DISPLAY_AVATAR_OPTION.PROFILE_IMAGE:
      return null;
    case DISPLAY_AVATAR_OPTION.COMPANY_LOGO:
      // Most RR links will fall here. Fallack to uploaded avatar
      return companyAvatar || customAvatar;
    case DISPLAY_AVATAR_OPTION.CUSTOM_AVATAR:
      return customAvatar;
    default:
      // Hotfix for unset displayAvatarOption
      return isPersonal ? customAvatar || null : customAvatar || companyAvatar || null;
  }
}
export const getCalendarSettingsUrl = () => `${getFullUrl('app')}/settings/${PortalIdParser.get()}/user-preferences/calendar?connectCalendar=true`;
export const getPrepareUrl = eventId => `${getFullUrlWithCurrentSubDomain()}/schedule-workspace-iframe/${PortalIdParser.get()}/prepare/${eventId}`;
export const getReviewUrl = eventId => `${getFullUrlWithCurrentSubDomain()}/schedule-workspace-iframe/${PortalIdParser.get()}/review/${eventId}`;
export const getTurnOnSchedulingPagesUrl = () => `${getFullUrl('app')}/settings/${PortalIdParser.get()}/user-preferences/calendar?schedulingPageEnabled=true`;
export function isLocalDomain(href) {
  const url = new Url(href);
  // @ts-expect-error improper api usage
  return url._getLocal() || /local.hsappstatic.net/.test(url.hostname); // Unit tests run on local.hsappstatic.net
}
export function isAllowedToTroubleshootMeetingsLink(href) {
  return href.indexOf(getAdminDomain()) > -1 || isLocalDomain(href);
}