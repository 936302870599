import { Record } from 'immutable';

// TODO: Narrow down types of `fieldType` and `type`

const defaults = {
  fieldType: null,
  isCustom: true,
  isRequired: true,
  label: '',
  name: '',
  numberDisplayHint: null,
  options: [],
  type: null
};
export default class FormField extends Record(defaults) {
  static from(json) {
    return new FormField(json);
  }
  static add(isCustom, name, label, numberDisplayHint,
  // TODO: `options` seems to be always passed as a List<FormFieldOption>
  fieldData) {
    return new FormField(Object.assign({
      isCustom,
      name,
      label,
      numberDisplayHint
    }, fieldData));
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}