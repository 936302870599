import { registerQuery } from 'data-fetching-client';
import { fetchAvailabilityPage, fetchV3PublicAvailabilityInfo } from '../api/bookingAPIV3';
export const fetchV3PublicAvailabilityInfoQuery = registerQuery({
  fieldName: 'publicAvailabilityInfo',
  args: ['slug', 'now'],
  fetcher: queryParams => fetchV3PublicAvailabilityInfo(queryParams)
});
export const fetchAvailabilityPageQuery = registerQuery({
  fieldName: 'publicAvailabilityPage',
  args: ['monthOffset', 'slug', 'timezone'],
  fetcher: queryParams => fetchAvailabilityPage(queryParams)
});