export const KEYS = {
  IS_LEGITIMATE_INTEREST: 'isLegitimateInterest',
  LEGITIMATE_INTEREST_TEXT: 'legitimateInterestPrivacyPolicyText',
  LEGITIMATE_INTEREST_SUBSCRIPTIONS: 'legitimateInterestSubscriptionTypes',
  LEGITIMATE_INTEREST_LAWFUL_BASIS: 'legitimateInterestLegalBasis',
  COMMUNICATION_CONSENT_TEXT: 'communicationConsentText',
  COMMUNICATION_CONSENT_CHECKBOXES: 'communicationConsentCheckboxes',
  IMPLICIT_PROCESSING_CONSENT_TEXT: 'implicitProcessingConsentText',
  PRIVACY_POLICY_TEXT: 'privacyPolicyText'
};
export const CHECKBOX_KEYS = {
  COMMUNICATION_TYPE_ID: 'communicationTypeId',
  LABEL: 'label'
};
export const FORMS_KEYS = {
  PROCESSING_CONSENT_TYPE: 'processingConsentType',
  PROCESSING_CONSENT_TEXT: 'processingConsentText'
};
export const IMPLICIT_PROCESSING_CONSENT_TYPE = 'IMPLICIT';
export const GDPR_COMPLIANCE_ENABLED = 'GDPRComplianceEnabled';
export const LAWFUL_BASIS_PQL = 'LEGITIMATE_INTEREST_PQL';
export const ONE_TO_ONE = 'ONE_TO_ONE';