import I18n from 'I18n';

/** @internal */

/** @internal */

/** @internal */

/** @internal */

/**
 * Returns data for the current user's locale according to MomentJS. It's important that this not
 * be called until I18n is ready. For UIComponents, this means waiting until render.
 */
export const getLocaleData = () => {
  const locale = (I18n.moment.locale || I18n.moment.lang)();
  return (I18n.moment.localeData || I18n.moment.langData)(locale);
};
export const getLocalizedDateFormat = format => {
  const longDateFormat = getLocaleData().longDateFormat(format) || format;
  return longDateFormat;
};
export const getDatePlaceholder = format => getLocalizedDateFormat(format).replace(/MM/, I18n.text('ui.datePicker.placeholder.month')).replace(/DD/, I18n.text('ui.datePicker.placeholder.day')).replace(/YYYY/, I18n.text('ui.datePicker.placeholder.year'));