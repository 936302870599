/* hs-eslint ignored failing-rules */
/* eslint-disable react/prop-types */

'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["className", "input", "label"];
import { Component } from 'react';
import PropTypes from 'prop-types';
import * as CustomRenderer from 'UIComponents/utils/propTypes/customRenderer';
import createLazyPropType from 'UIComponents/utils/propTypes/createLazyPropType';
import lazyEval from 'UIComponents/utils/lazyEval';
import classNames from 'classnames';
export default class SingleNameInput extends Component {
  render() {
    const _this$props = this.props,
      {
        className,
        input,
        label
      } = _this$props,
      passThroughProps = _objectWithoutPropertiesLoose(_this$props, _excluded);
    const inputClass = input.props && input.props.className;
    return CustomRenderer.render(input, Object.assign({
      label: lazyEval(label)
    }, passThroughProps, input.props, {
      className: classNames(inputClass, className)
    }), {
      mergeType: 'aggressive'
    });
  }
}
SingleNameInput.propTypes = {
  input: CustomRenderer.propType.isRequired,
  label: createLazyPropType(PropTypes.node).isRequired
};