// @ts-expect-error untyped import
import { handleActions } from 'flux-actions';
import getIn from 'transmute/getIn';
// @ts-expect-error untyped import
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
const initialState = '';
export default handleActions({
  [ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED](state, action) {
    if (state !== '') {
      return state;
    }
    return getIn(['bookInfo', 'customParams', 'locale'], action.payload);
  }
}, initialState);