import { Record } from 'immutable';
const defaults = {
  hubs_content: null,
  'hubs_content-cta': null,
  hubs_offer: null,
  hubs_post: null,
  'hubs_post-cta': null,
  'hubs_signup-cta': null,
  'hubs_signup-url': null,
  meeting_campaign: null,
  meeting_funnel: null,
  meeting_medium: null,
  meeting_product: null,
  meeting_source: null,
  meeting_type: null,
  referrer: null,
  source_app: null,
  utm_campaign: null,
  utm_content: null,
  utm_medium: null,
  utm_source: null,
  utm_term: null
};
export default class TrackingParams extends Record(defaults) {
  static from(json) {
    return new TrackingParams(json);
  }
  compact() {
    return this.toSeq().reduce((truthyFields, fieldValue, fieldName) => {
      if (fieldValue) {
        truthyFields[fieldName] = fieldValue;
      }
      return truthyFields;
    }, {});
  }
}