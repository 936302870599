'use es6';

import { Flow } from 'signup-constants/Flow';
import { HubsTrackingAttr } from 'signup-constants/HubsTrackingAttr';
import { Intent } from 'signup-constants/Intent';
import { SignupQueryKeys } from 'signup-constants/SignupQueryKeys';
import { UtmTrackingAttr } from 'signup-constants/UtmTrackingAttr';
import { getTrackedSignupUrl } from 'viral-links-lib/viral-tracking/viral-urls';
export default function getFreemiumViralLink(viralPage, portalId, embedded = false, thankyou = false) {
  const base = 'meetings';
  const link = thankyou ? `${base}-thankyou` : base;
  const viralLinkType = embedded ? `${link}-embedded` : link;
  const campaign = `hubspot-${viralLinkType}-virality`;
  return getTrackedSignupUrl({
    flow: Flow.Sales,
    viralLinkType,
    additionalParams: {
      [HubsTrackingAttr.Campaign]: campaign,
      [HubsTrackingAttr.Cta]: viralPage,
      [SignupQueryKeys.Intent]: Intent.salesMeetings,
      [SignupQueryKeys.OptSidebar]: 'meeting',
      [UtmTrackingAttr.Medium]: 'virality',
      [SignupQueryKeys.ViralSourcePortalId]: portalId
    }
  });
}