import { Record, List } from 'immutable';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import PublicMeetingsUser from 'MeetingsBase/models/PublicMeetingsUser';
import TimeRange from 'MeetingsBase/models/TimeRange';
const defaults = {
  busyTimes: List(),
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  meetingsUser: null,
  offline: false
};
export default class UserBusyTimes extends Record(defaults) {
  static from(json) {
    return new UserBusyTimes({
      id: json.meetingsUser.id,
      busyTimes: List(json.busyTimes.map(b => TimeRange.from(b))),
      offline: json.isOffline,
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'null | u... Remove this comment to see the full error message
      firstName: getIn(['meetingsUser', 'userProfile', 'firstName'], json),
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'null | u... Remove this comment to see the full error message
      lastName: getIn(['meetingsUser', 'userProfile', 'lastName'], json),
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'null | u... Remove this comment to see the full error message
      email: getIn(['meetingsUser', 'userProfile', 'email'], json),
      meetingsUser: PublicMeetingsUser.from(get('meetingsUser', json))
    });
  }
  convertToJSON() {
    const {
      busyTimes,
      email,
      firstName,
      id,
      lastName,
      meetingsUser,
      offline
    } = this;
    return {
      busyTimes: busyTimes.toArray().map(busyTime => busyTime.toJSON()),
      email,
      firstName,
      id,
      lastName,
      meetingsUser: meetingsUser ? meetingsUser.convertToJSON() : null,
      offline
    };
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}