import { useEffect } from 'react';
export const useBeforeUnload = enabled => {
  return useEffect(() => {
    const handleBeforeUnload = evt => {
      evt.preventDefault();
      const returnValue = '';
      evt.returnValue = returnValue;
      return returnValue;
    };
    if (!enabled) return undefined;
    window.addEventListener('beforeunload', handleBeforeUnload, {
      capture: true
    });
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload, {
        capture: true
      });
    };
  }, [enabled]);
};