import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { PUBLIC_BOOK_ENDPOINT_V3 } from '../constants/endpoints';
// @ts-expect-error no defined types yet
import { hubletAwareApi } from 'MeetingsLib/utils/utils';
import { retryableGetClient } from './hubHttpNoAuthRetryableClient';
export const PUBLIC_AVAILABILITY_INFO_FETCH_RETRY_STATUS_CODES = [500, 0];

// TODO: Reconcile the types below with @kleong TS migrations for MeetingsBase

export function fetchV3PublicAvailabilityInfo(queryParams, useRetryClient = false) {
  const options = {
    withCredentials: false,
    query: queryParams,
    timeout: 12000,
    api: hubletAwareApi
  };
  if (useRetryClient) {
    return retryableGetClient({
      retryCount: 2,
      retryStatusCodes: PUBLIC_AVAILABILITY_INFO_FETCH_RETRY_STATUS_CODES,
      url: `${PUBLIC_BOOK_ENDPOINT_V3}`,
      options
    });
  }
  return noAuthApiClient.get(`${PUBLIC_BOOK_ENDPOINT_V3}`, Object.assign({}, options));
}
export function fetchAvailabilityPage(queryParams, useRetryClient = false) {
  const options = {
    withCredentials: false,
    query: queryParams,
    timeout: 12000,
    api: hubletAwareApi
  };
  if (useRetryClient) {
    return retryableGetClient({
      retryCount: 2,
      retryStatusCodes: PUBLIC_AVAILABILITY_INFO_FETCH_RETRY_STATUS_CODES,
      url: `${PUBLIC_BOOK_ENDPOINT_V3}/availability-page`,
      options
    });
  }
  return noAuthApiClient.get(`${PUBLIC_BOOK_ENDPOINT_V3}/availability-page`, Object.assign({}, options));
}