import { Map as ImmutableMap } from 'immutable';
export const CAL_INTEGRATIONS = {
  GOOGLE: 'GOOGLE',
  OFFICE365: 'OFFICE365',
  EXCHANGE: 'EXCHANGE',
  UNKNOWN: 'UNKNOWN'
};
export const FORM_FIELD_TYPES = {
  DATE: 'date',
  NUMBER: 'number',
  TEXT: 'string',
  TEXTAREA: 'textarea',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  BOOLEAN_CHECKBOX: 'booleancheckbox',
  ENUMERATION: 'enumeration',
  SELECT: 'select',
  PHONE_NUMBER: 'phonenumber'
};
export const FORM_FIELDS_WITH_OPTIONS = [FORM_FIELD_TYPES.RADIO, FORM_FIELD_TYPES.CHECKBOX, FORM_FIELD_TYPES.BOOLEAN_CHECKBOX, FORM_FIELD_TYPES.ENUMERATION];
export const DEFAULT_FORM_FIELD_NAMES = {
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL: 'EMAIL'
};
export const MEETING_TYPES = {
  PERSONAL: 'personal',
  GROUP: 'group',
  ROUND_ROBIN: 'roundRobin'
};
export const LINK_TYPES = ImmutableMap({
  personal: 'PERSONAL_LINK',
  group: 'GROUP_CALENDAR',
  roundRobin: 'ROUND_ROBIN_CALENDAR'
});
export const LINK_TO_MEETING_TYPES = ImmutableMap({
  PERSONAL_LINK: 'personal',
  GROUP_CALENDAR: 'group',
  ROUND_ROBIN_CALENDAR: 'round robin'
});
export const LINK_TYPE_PARAMS = ImmutableMap({
  personal: 'personal',
  team: 'team'
});
export const AUTH_ERROR_TYPES = {
  USER_DECLINED: 'USER_DECLINED',
  SHARED_INBOX_INVALID_DOWNGRADE: 'SHARED_INBOX_INVALID_DOWNGRADE',
  DEFAULT: 'DEFAULT',
  SHARED_INBOX: 'SHARED_INBOX'
};
export const START_TIME_INTERVALS = {
  MEETING_DURATION: 'MEETING_DURATION',
  FIVE: 'FIVE',
  TEN: 'TEN',
  FIFTEEN: 'FIFTEEN',
  TWENTY: 'TWENTY',
  THIRTY: 'THIRTY',
  FORTY_FIVE: 'FORTY_FIVE',
  SIXTY: 'SIXTY',
  NINETY: 'NINETY',
  ONE_HUNDRED_TWENTY: 'ONE_HUNDRED_TWENTY'
};
export const START_TIME_INTERVAL_VALUES = {
  MEETING_DURATION: 'MEETING_DURATION',
  FIVE: 5,
  TEN: 10,
  FIFTEEN: 15,
  TWENTY: 20,
  THIRTY: 30,
  FORTY_FIVE: 45,
  SIXTY: 60,
  NINETY: 90,
  ONE_HUNDRED_TWENTY: 120
};
export const DISPLAY_AVATAR_OPTION = {
  PROFILE_IMAGE: 'PROFILE_IMAGE',
  COMPANY_LOGO: 'COMPANY_LOGO',
  CUSTOM_AVATAR: 'CUSTOM_AVATAR'
};