'use es6';

import get from 'transmute/get';
import getLang from 'I18n/utils/getLang';
import getLangLocale from 'I18n/utils/getLangLocale';
export const LocaleToGoogleLang = {
  en: 'en',
  es: 'es',
  de: 'de',
  ja: 'ja',
  fr: 'fr',
  'pt-br': 'pt-BR',
  'es-mx': 'es',
  af: 'af',
  'ar-eg': 'ar',
  bg: 'bg',
  bn: 'bn',
  'ca-es': 'ca',
  cs: 'cs',
  da: 'da',
  el: 'el',
  fi: 'fi',
  hr: 'hr',
  hu: 'hu',
  id: 'id',
  it: 'it',
  ko: 'ko',
  nl: 'nl',
  'no-no': 'no',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  sl: 'sl',
  sv: 'sv',
  th: 'th',
  tr: 'tr',
  vi: 'vi',
  uk: 'uk',
  'zh-cn': 'zh-CN',
  'zh-hk': 'zh-HK'
};
export const getActiveLocale = get('activeLocale');
export const getGoogleActiveLocale = () => LocaleToGoogleLang[getLang() === 'en' ? getLang() : getLangLocale()];