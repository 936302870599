import { createSelector } from 'reselect';
// @ts-expect-error need definitions
import { getPaymentLinkInfo } from 'MeetingsLib/selectors/BookInfoSelectors';
export const paidMeetingLifecycleSelector = state => state.paidMeetingStatus;
export const getPaymentStatusSelector = createSelector([paidMeetingLifecycleSelector], paidMeetingLifecyle => paidMeetingLifecyle.paymentStatus);
export const getPaymentSuccessfulSelector = createSelector([paidMeetingLifecycleSelector], paidMeetingStatus => paidMeetingStatus.paymentStatus === 'SUCCESS');
export const getPaymentFailedSelector = createSelector([paidMeetingLifecycleSelector], paidMeetingStatus => paidMeetingStatus.paymentStatus === 'FAILED');
export const getPaymentProcessingSelector = createSelector([paidMeetingLifecycleSelector], paidMeetingStatus => paidMeetingStatus.paymentStatus === 'PROCESSING');
export const getPaymentUninitializedSelector = createSelector([paidMeetingLifecycleSelector], paidMeetingStatus => paidMeetingStatus.paymentStatus === 'UNINITIALIZED');
export const getPaymentSessionId = createSelector([paidMeetingLifecycleSelector], paidMeetingStatus => paidMeetingStatus.paymentSessionId);
export const getPaidMeetingPrice = createSelector([paidMeetingLifecycleSelector, getPaymentLinkInfo], (paidMeetingStatus, paymentLinkInfo) => paidMeetingStatus.finalPrice || paymentLinkInfo && paymentLinkInfo.total);
export const getPaymentInfoForBE = createSelector([getPaidMeetingPrice, getPaymentSessionId, getPaymentLinkInfo], (finalPrice, sessionId, paymentInfo) => {
  return {
    totalAmount: finalPrice,
    currencyCode: paymentInfo.currency,
    paymentsSessionId: sessionId
  };
});