import { Record, Map as ImmutableMap, List } from 'immutable';
import I18n from 'I18n';
import TimeRange from 'MeetingsBase/models/TimeRange';
import { unify } from 'MeetingsBase/utils/TimeRangeListUtils';
import { validTimeRange } from 'MeetingsBase/utils/dates';
import { AVAILABILITY_OPTIONS, WEEKDAYS_LIST, AVAILABLE_DAYS } from 'MeetingsBase/constants/dates';
const defaults = Object.keys(AVAILABILITY_OPTIONS).reduce((accumulator, day) => Object.assign({}, accumulator, {
  [day]: List()
}), {});
export default class Availability extends Record(defaults) {
  static from(json) {
    const availability = this._fromWithoutValidation(json);
    return availability.reduce((newAvailability, timeRanges, dayKey) => {
      newAvailability = newAvailability.set(dayKey, timeRanges.filter(timeRange => validTimeRange(timeRange)));
      return newAvailability;
    }, new Availability());
  }
  static _fromWithoutValidation(json) {
    if (!json) {
      return new Availability();
    }
    const jsonMap = ImmutableMap(json);
    const formattedData = jsonMap.map(timeRanges => {
      if (!timeRanges) {
        return timeRanges;
      }
      const timeRangesList = List(timeRanges);
      return timeRangesList.map(TimeRange.from);
    });
    const sortedAvailability = formattedData.map(timeRanges => timeRanges ? timeRanges.sort((timeRangeA, timeRangeB) => timeRangeA.end - timeRangeB.end) : timeRanges);
    return sortedAvailability;
  }
  isValid() {
    return Object.keys(AVAILABILITY_OPTIONS).every(option => !this.has(option) || this.get(option).every(timeRange => validTimeRange(timeRange)));
  }
  isEmpty() {
    return this.reduce((t, a) => t + a.size, 0) === 0;
  }
  getForDateString(dateString) {
    const weekDayIndex = I18n.moment(dateString).day();
    return this.getForWeekDayIndex(weekDayIndex);
  }
  getUnmergedForWeekDay(weekDay) {
    switch (weekDay) {
      case AVAILABLE_DAYS.SATURDAY:
      case AVAILABLE_DAYS.SUNDAY:
        return this[weekDay].concat(this[AVAILABILITY_OPTIONS.SAT_SUN]).concat(this[AVAILABILITY_OPTIONS.EVERY_DAY]);
      case AVAILABLE_DAYS.MONDAY:
      case AVAILABLE_DAYS.TUESDAY:
      case AVAILABLE_DAYS.WEDNESDAY:
      case AVAILABLE_DAYS.THURSDAY:
      case AVAILABLE_DAYS.FRIDAY:
        return this[weekDay].concat(this[AVAILABILITY_OPTIONS.MON_FRI]).concat(this[AVAILABILITY_OPTIONS.EVERY_DAY]);
      default:
        return this[weekDay].concat(this[AVAILABILITY_OPTIONS.EVERY_DAY]);
    }
  }
  getForWeekDayIndex(index) {
    const weekDay = WEEKDAYS_LIST[index];
    const unmergedAvailabilityForWeekDay = this.getUnmergedForWeekDay(weekDay);
    return unify(unmergedAvailabilityForWeekDay);
  }
  setForWeekDayIndex(availability, index) {
    return this.set(WEEKDAYS_LIST[index], availability);
  }
  reduce(accumulator, initial) {
    const keys = Object.keys(AVAILABILITY_OPTIONS);
    return keys.reduce((acc, option) => accumulator(acc, this.get(option), option), initial);
  }
  convertToJSON() {
    const {
      EVERY_DAY,
      FRIDAY,
      MONDAY,
      MON_FRI,
      SATURDAY,
      SAT_SUN,
      SUNDAY,
      THURSDAY,
      TUESDAY,
      WEDNESDAY
    } = this;
    return {
      EVERY_DAY: EVERY_DAY.toArray().map(range => range.toJSON()),
      FRIDAY: FRIDAY.toArray().map(range => range.toJSON()),
      MONDAY: MONDAY.toArray().map(range => range.toJSON()),
      MON_FRI: MON_FRI.toArray().map(range => range.toJSON()),
      SATURDAY: SATURDAY.toArray().map(range => range.toJSON()),
      SAT_SUN: SAT_SUN.toArray().map(range => range.toJSON()),
      SUNDAY: SUNDAY.toArray().map(range => range.toJSON()),
      THURSDAY: THURSDAY.toArray().map(range => range.toJSON()),
      TUESDAY: TUESDAY.toArray().map(range => range.toJSON()),
      WEDNESDAY: WEDNESDAY.toArray().map(range => range.toJSON())
    };
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}