import { List } from 'immutable';
import Availability from 'MeetingsBase/models/Availability';
import TimeRange from 'MeetingsBase/models/TimeRange';
import AvailableTimeChunk from 'MeetingsBase/models/AvailableTimeChunk';
export function getChunkListFromRecord(availability) {
  return availability.reduce((acc, listTimeRanges, day) => {
    const timeChunksForDay = listTimeRanges.map(timeRange => new AvailableTimeChunk({
      day,
      startMinute: timeRange.start,
      endMinute: timeRange.end
    }));
    return acc.concat(timeChunksForDay);
  }, List());
}
export function getRecordFromChunkList(availableTimeChunks) {
  return availableTimeChunks.reduce((acc, timeChunk) => {
    return acc.update(timeChunk.day, timeChunksForDay => timeChunksForDay.push(TimeRange.from({
      start: timeChunk.startMinute,
      end: timeChunk.endMinute
    })));
  }, new Availability());
}