import { List, Record } from 'immutable';
import TroubleshootTimeIntervalRecord from './TroubleshootTimeInterval';
const troubleshootTimesByDurationRecordDefaults = {
  troubleshootTimes: List(),
  meetingDurationMillis: 0
};
export default class TroubleshootTimesByDurationRecord extends Record(troubleshootTimesByDurationRecordDefaults) {
  static from(json) {
    return new TroubleshootTimesByDurationRecord({
      meetingDurationMillis: json.meetingDurationMillis,
      troubleshootTimes: List(json.troubleshootTimes.map(TroubleshootTimeIntervalRecord.from))
    });
  }

  /**
   * @deprecated use convertToJSON instead
   * @returns
   */
  toJS() {
    return super.toJS();
  }

  /**
   * @deprecated use convertToJSON instead
   */
  toJSON() {
    return super.toJSON();
  }
  convertToJSON() {
    return {
      meetingDurationMillis: this.get('meetingDurationMillis'),
      troubleshootTimes: this.get('troubleshootTimes').map(troubleshootTime => {
        return troubleshootTime.convertToJSON();
      }).toJS()
    };
  }
}