/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-unsafe-i18n-at-module-eval */

import I18n from 'I18n';
import TimeRange from 'MeetingsBase/models/TimeRange';

// durations in milliseconds
export const MINUTES_TO_MILLIS = 60 * 1000;
export const ONE_HOUR = 60 * MINUTES_TO_MILLIS;
export const ONE_DAY = 60 * 24 * MINUTES_TO_MILLIS;

// Durations
export const ZERO_DURATION = I18n.moment.duration(0).asMinutes();
const getDurationAsMillis = duration => I18n.moment.duration({
  minutes: duration
}).asMilliseconds();
const getDurationAsMinutes = duration => I18n.moment.duration({
  minutes: duration
}).asMinutes();
export const FIFTEEN_MINUTE_DURATION = getDurationAsMinutes(15);
export const THIRTY_MINUTE_DURATION = getDurationAsMinutes(30);
export const HOUR_DURATION = getDurationAsMinutes(60);
export const DAY_DURATION = I18n.moment.duration({
  hours: 24
}).asMinutes();
export const TEN_MINUTE_MILLIS = getDurationAsMillis(10);
export const FIFTEEN_MINUTE_MILLIS = getDurationAsMillis(15);
export const THIRTY_MINUTE_MILLIS = getDurationAsMillis(30);
export const FORTY_FIVE_MINUTE_MILLIS = getDurationAsMillis(45);
export const SIXTY_MINUTE_MILLIS = getDurationAsMillis(60);
export const ONE_HOUR_FIFTEEN_MINUTE_MILLIS = 4500000;
export const ONE_HOUR_THIRTY_MINUTE_MILLIS = 5400000;
export const ONE_HOUR_FORTY_FIVE_MINUTE_MILLIS = 6300000;
export const TWO_HOURS_MILLIS = 7200000;
export const DAY_TIME_RANGE = TimeRange.from({
  start: ZERO_DURATION,
  end: DAY_DURATION
});

// The value to store for weeksToAdvertise when using custom availability.
// (weeksToAdvertise requires an integer value)
export const CUSTOM_AVAILABILITY = 0;
export const TWO_WEEKS = 2;
export const MAX_CUSTOM_AVAILABILITY_WEEKS = 10;
export const AVAILABLE_DAYS = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY'
};
export const AVAILABILITY_OPTIONS = Object.assign({
  EVERY_DAY: 'EVERY_DAY',
  MON_FRI: 'MON_FRI',
  SAT_SUN: 'SAT_SUN'
}, AVAILABLE_DAYS);
export const WEEKDAYS_LIST = [AVAILABLE_DAYS.SUNDAY, AVAILABLE_DAYS.MONDAY, AVAILABLE_DAYS.TUESDAY, AVAILABLE_DAYS.WEDNESDAY, AVAILABLE_DAYS.THURSDAY, AVAILABLE_DAYS.FRIDAY, AVAILABLE_DAYS.SATURDAY];