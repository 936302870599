import { Record } from 'immutable';
import { FIFTEEN_MINUTE_DURATION } from 'MeetingsBase/constants/dates';
const defaults = {
  bookingTimeConflictStatus: false,
  dateString: null,
  dateTime: null,
  duration: FIFTEEN_MINUTE_DURATION,
  formFields: null
};
export default class Event extends Record(defaults, 'Event') {
  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}