import { useQuery } from 'data-fetching-client';
import { fetchTroublshootTimesPageQuery, fetchV3TroubleshootTimesQuery } from 'MeetingsLib/query/troubleshootTimesQuery';
export const useTroubleshootTimes = (params, shouldSkip) => {
  const {
    slug
  } = params;
  const skip = shouldSkip || !slug;
  const {
    data,
    loading,
    refetch,
    error
  } = useQuery(fetchV3TroubleshootTimesQuery, {
    variables: Object.assign({}, params),
    fetchPolicy: 'cache-and-network',
    skip
  });
  const troubleshootTimes = data && data.troubleshootTimes;
  return {
    troubleshootTimes,
    loading,
    error,
    refetch
  };
};
export const useTroubleshootTimesPage = (params, shouldSkip) => {
  const {
    slug,
    monthOffset
  } = params;
  const skip = shouldSkip || !slug || monthOffset < 0 || monthOffset > 14;
  const {
    data,
    error,
    loading,
    refetch
  } = useQuery(fetchTroublshootTimesPageQuery, {
    variables: Object.assign({}, params),
    fetchPolicy: 'cache-first',
    skip
  });
  const troubleshootTimesPage = data && data.troubleshootTimesPage;
  return {
    troubleshootTimesPage,
    loading,
    error,
    refetch
  };
};