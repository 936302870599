'use es6';

import I18n from 'I18n';
import { List } from 'immutable';
import { createSelector } from 'reselect';
import { getBookInfo, getMeetingLength, getUserTimezone, getEvent } from 'MeetingsLib/selectors/BookInfoSelectors';
import { getIsFetchingPublicAvailability, availabilityFetchFailed as getAvailabilityFetchFailed } from 'MeetingsLib/selectors/RequestStatusSelectors';
export const getLinkAvailabilityByDuration = createSelector([getIsFetchingPublicAvailability, getAvailabilityFetchFailed, getBookInfo], (isFetchingPublicAvailability, availabilityFetchFailed, bookInfo) => {
  const availabilityByDuration = bookInfo.getIn(['linkAvailability', 'linkAvailabilityByDuration']);
  if (isFetchingPublicAvailability || availabilityFetchFailed || !availabilityByDuration) {
    return List();
  }
  return availabilityByDuration;
});
export const getLinkAvailability = createSelector([getBookInfo], bookInfo => {
  return bookInfo.get('linkAvailability');
});
export const getAvailabilityForSelectedDuration = createSelector([getIsFetchingPublicAvailability, getAvailabilityFetchFailed, getLinkAvailabilityByDuration, getMeetingLength], (isFetchingPublicAvailability, availabilityFetchFailed, linkAvailabilityByDuration, duration) => {
  if (isFetchingPublicAvailability || availabilityFetchFailed || !linkAvailabilityByDuration.size) {
    return List();
  }
  return linkAvailabilityByDuration.find(availabilityByDuration => availabilityByDuration.get('meetingDurationMillis') === duration).get('availabilities');
});
export const getAvailableStartTimes = createSelector([getAvailabilityForSelectedDuration], availabilities => availabilities.map(availability => availability.get('startMillisUtc')));
export const getGroupedAvailableTimes = createSelector([getAvailableStartTimes, getUserTimezone], (startTimes, userTimezone) => startTimes.groupBy(startMillisUtc => I18n.moment(startMillisUtc).tz(userTimezone).format('YYYY-MM-DD')));
export const getAvailableDates = createSelector([getGroupedAvailableTimes], groupedAvailableTimes => {
  return groupedAvailableTimes.keySeq();
});
export const isSelectedDateAvailable = createSelector([getAvailableDates, getEvent], (availableDates, event) => availableDates.includes(event.dateString));
export const getAvailableStartTimesForSelectedDate = createSelector([getGroupedAvailableTimes, getEvent], (groupedAvailableTimes, event) => {
  return groupedAvailableTimes.get(event.dateString) || List();
});