/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-for-in-array */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */

import I18n from 'I18n';
export default (({
  groups
}) => groups.map(tzg => {
  return {
    text: tzg.groupName,
    value: tzg.groupName,
    options: tzg.timezones.sort((x, y) => parseInt(x.offset, 10) - parseInt(y.offset, 10)).map(tz => {
      return {
        text: I18n.text('timezone_utils.utc_plus_minus', {
          offset: tz.offset,
          timezone: I18n.SafeString(tz.name)
        }),
        value: tz.identifier()
      };
    }).toJS()
  };
}).sort((x, y) => {
  const mainCountries = ['US', 'Europe', 'Canada'];
  for (let i in mainCountries) {
    if (x.text === mainCountries[i]) {
      return -1;
    }
    if (y.text === mainCountries[i]) {
      return 1;
    }
  }
  return 0;
}).toArray());