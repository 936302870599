import { Record } from 'immutable';
const defaults = {
  firstName: '',
  lastName: '',
  email: '',
  fullName: '',
  name: '',
  userId: null
};
export default class UserProfile extends Record(defaults) {
  static from(json) {
    return new UserProfile(json);
  }
  static fromUser(json) {
    return new UserProfile(Object.assign({
      firstName: json.first_name,
      lastName: json.last_name,
      userId: json.user_id
    }, json));
  }
}