// @ts-expect-error untyped import
import { handleActions } from 'flux-actions';
import PaidMeetingLifecycle from 'MeetingsBase/models/PaidMeetingLifecycle';
// @ts-expect-error untyped import
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
const initialState = new PaidMeetingLifecycle();
export default handleActions({
  [ActionTypes.SET_PAYMENT_STATUS](state, action) {
    return state.set('paymentStatus', action.payload);
  },
  [ActionTypes.SET_PAYMENT_SESSION_ID](state, action) {
    return state.set('paymentSessionId', action.payload);
  },
  [ActionTypes.SET_PAYMENT_FINAL_PRICE](state, action) {
    return state.set('finalPrice', action.payload);
  }
}, initialState);