import enviro from 'enviro';

// Do not remove this.
// Marketing Ops relies on this convert.com analytics
// container to be loaded on all links that are owned by 53
const HUBSPOT_INTERNAL_PORTAL = 53;
export default function (portalId) {
  const key = 'convert_analytics';
  if (portalId === HUBSPOT_INTERNAL_PORTAL && enviro.deployed(key) && enviro.getInternal(key) === 'prod') {
    var _firstScript$parentNo;
    const firstScript = document.getElementsByTagName('script')[0];
    const tag = document.createElement('script');
    tag.id = 'hubspot-convert-container';
    tag.async = true;
    tag.src = '//cdn-3.convertexperiments.com/js/10031559-1003891.js';
    (_firstScript$parentNo = firstScript.parentNode) === null || _firstScript$parentNo === void 0 || _firstScript$parentNo.insertBefore(tag, firstScript);
  }
}