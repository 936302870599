import { Record } from 'immutable';
export const DEFAULT_INPUT_UNIT_VALUE = 'WEEKS';
export const MIN_DATE_VALUE = 2;
export const MAX_BUSINESS_DAYS = 55;
export const MAX_CALENDAR_DAYS = 77;
export const MAX_WEEKS = 11;

// Increased MAXes for Meetings:AvailabilityDaysLimit
export const MAX_BUSINESS_DAYS_INCREASED = 300;
export const MAX_CALENDAR_DAYS_INCREASED = 400;
export const MAX_WEEKS_INCREASED = 60;
export const OFFLINE_DAYS = ['SATURDAY', 'SUNDAY'];
const defaults = {
  rollingDateValue: MIN_DATE_VALUE,
  rollingDateUnit: 'WEEKS',
  offlineDays: []
};
export default class RollingDateRange extends Record(defaults) {
  static from(json) {
    return new RollingDateRange(json);
  }
  equals(other) {
    const firstJson = this.toJSON();
    const secondJson = other.toJSON();
    return JSON.stringify(firstJson) === JSON.stringify(secondJson);
  }

  /**
   * @deprecated Use toJSON instead of toJS.
   * This method will not return the correct underlying types for this record
   */
  toJS() {
    return super.toJS();
  }
  getMaxValueForDateUnit() {
    if (this.offlineDays.length > 0) {
      return MAX_BUSINESS_DAYS_INCREASED;
    } else if (this.rollingDateUnit === 'DAYS') {
      return MAX_CALENDAR_DAYS_INCREASED;
    }
    return MAX_WEEKS_INCREASED;
  }
  buildDateRangeInputsValue() {
    const rollingDateRangeProps = this.toJSON();
    if (rollingDateRangeProps.offlineDays.length >= 1) {
      return Object.assign({}, rollingDateRangeProps, {
        rollingDateUnit: 'BUSINESS_DAYS'
      });
    }
    return rollingDateRangeProps;
  }
  hasValidDateValue() {
    return this.rollingDateValue <= this.getMaxValueForDateUnit();
  }
  validateDateValue() {
    return new RollingDateRange({
      rollingDateUnit: this.rollingDateUnit,
      offlineDays: this.offlineDays,
      rollingDateValue: Math.min(this.rollingDateValue, this.getMaxValueForDateUnit())
    });
  }
  static fromRollingDateRangeInputsValue(rollingDateRange) {
    const {
      rollingDateUnit,
      rollingDateValue
    } = rollingDateRange;
    const rollingDateRangeEgg = {
      rollingDateUnit: rollingDateUnit === 'BUSINESS_DAYS' ? 'DAYS' : rollingDateUnit,
      rollingDateValue,
      offlineDays: rollingDateUnit === 'BUSINESS_DAYS' ? OFFLINE_DAYS : []
    };
    return new RollingDateRange(rollingDateRangeEgg);
  }
  static getEmptyRollingDateRange() {
    return new RollingDateRange({
      rollingDateValue: 0,
      rollingDateUnit: 'WEEKS',
      offlineDays: []
    });
  }
}