import { Map as ImmutableMap } from 'immutable';
import Availability from 'MeetingsBase/models/Availability';
function toMilliseconds(availability) {
  return availability.reduce((reduction, day, key) => reduction.set(key, day.map(timeRange => timeRange.toMilliseconds())), ImmutableMap());
}
export default class AvailabilityMilliseconds extends Availability {
  static from(data) {
    if (!data || Object.keys(data).length === 0) {
      return new AvailabilityMilliseconds(super.from(data));
    }
    return this.fromAvailability(Availability.from(data));
  }
  static fromAvailability(availability) {
    /**
     * @param {Availability} availability Configured weekly availability
     * @returns {AvailabilityMilliseconds} Configured weekly availability, expressed in
     * millisecond timestamps
     */
    if (!availability || Object.keys(availability).length === 0) {
      return new AvailabilityMilliseconds();
    }
    const availabilityInMilliseconds = toMilliseconds(availability);
    return new AvailabilityMilliseconds(availabilityInMilliseconds);
  }
}