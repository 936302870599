/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

import { Record } from 'immutable';
import { DEFAULT_COLOR } from 'MeetingsBase/constants/colors';
const defaults = {
  companyName: null,
  logoAltText: null,
  logoHeight: null,
  logoUrl: null,
  logoWidth: null,
  primaryColor: DEFAULT_COLOR
};
export default class Branding extends Record(defaults) {
  static from(json) {
    if (json) {
      const logoUrl = json.hasOwnProperty('logoUrl') ? json.logoUrl : json.companyLogo;
      const primaryColor = json.hasOwnProperty('primaryColor') ? json.primaryColor : json.accentColor;
      return new Branding({
        primaryColor: primaryColor || DEFAULT_COLOR,
        logoUrl,
        logoAltText: json.logoAltText,
        logoHeight: json.logoHeight,
        logoWidth: json.logoWidth,
        companyName: json.companyName
      });
    }
    return new Branding();
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}