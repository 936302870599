// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'flux... Remove this comment to see the full error message
import { createAction } from 'flux-actions';
import invariant from 'react-utils/invariant';
import * as RequestStatusTypes from 'MeetingsBase/constants/RequestStatusTypes';
function getStatusIndex(type) {
  const statusIndex = type ? type.lastIndexOf('_') : -1;
  if (statusIndex === -1) {
    throw new Error(`exected an request action type in the form of \${NAMESPACE}_\${REQUEST_STATUS} but received ${type}`);
  }
  return statusIndex;
}
function getRequestNamespace(type) {
  const statusIndex = getStatusIndex(type);
  return type.substring(0, statusIndex);
}
function getRequestStatus(type) {
  const statusIndex = getStatusIndex(type);
  const requestStatus = type.substring(statusIndex + 1);
  invariant(Object.prototype.hasOwnProperty.call(RequestStatusTypes, requestStatus), 'expected `requestStatus` to be defined in `MeetingsBase/constants/RequestStatusTypes` but got `%s`', requestStatus);
  return requestStatus;
}
export function createRequestStatusMeta(type) {
  const namespace = getRequestNamespace(type);
  const requestStatus = getRequestStatus(type);
  return {
    request: {
      key: namespace,
      status: requestStatus
    }
  };
}
export function createRequestAction(type, payloadCreator, metadataCreator) {
  return createAction(type, payloadCreator, (...payload) => Object.assign({}, metadataCreator ? metadataCreator(...payload) : {}, createRequestStatusMeta(type)));
}