import { Record } from 'immutable';
const defaults = {
  canAddGuests: false,
  maxGuestCount: 10
};
export default class GuestSettings extends Record(defaults) {
  static from(json) {
    return new GuestSettings({
      canAddGuests: json.canAddGuests,
      // This cannot currently change
      maxGuestCount: 10
    });
  }
  equals(other) {
    return JSON.stringify(this.toJSON()) === JSON.stringify(other.toJSON());
  }
  convertToJSON() {
    return {
      canAddGuests: this.canAddGuests,
      maxGuestCount: this.maxGuestCount
    };
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}