/* hs-eslint ignored failing-rules */
/* eslint-disable no-bitwise */

import { hexToRGB, getPrimaryColorOrDefault } from 'MeetingsBase/utils/color';
export function getColorStyleWithOpacity(primaryColor, opacity = 0.05) {
  const rgb = hexToRGB(primaryColor);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
}
export function shadeColor(color, percent) {
  /**
   * Source code from: https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
   * @param {string} color - A hex color string (must start with #)
   * @param {int} percent - Percentage by which to lighten the color, can be negative
   * to darken color
   * @returns {string} color - A color, lightened by the given percentage
   */
  const num = parseInt(color.slice(1), 16);
  let red = (num >> 16) + percent;
  if (red > 255) red = 255;else if (red < 0) red = 0;
  let blue = (num >> 8 & 0x00ff) + percent;
  if (blue > 255) blue = 255;else if (blue < 0) blue = 0;
  let green = (num & 0x0000ff) + percent;
  if (green > 255) green = 255;else if (green < 0) green = 0;
  return `#${(green | blue << 8 | red << 16).toString(16)}`;
}
export function getPrimaryButtonStyle(primaryColor) {
  const color = getPrimaryColorOrDefault(primaryColor);
  return {
    borderColor: color,
    backgroundColor: color
  };
}
export function getPrimaryButtonHoverStyle(primaryColor) {
  const color = getPrimaryColorOrDefault(primaryColor);
  return {
    borderColor: color,
    backgroundColor: color && shadeColor(color, 40)
  };
}
export function getSecondaryButtonStyle(primaryColor) {
  const color = getPrimaryColorOrDefault(primaryColor);
  return {
    borderColor: color,
    color
  };
}
export function getSecondaryButtonHoverStyle(primaryColor) {
  const color = getPrimaryColorOrDefault(primaryColor);
  return {
    borderColor: color,
    color,
    backgroundColor: color && getColorStyleWithOpacity(color)
  };
}