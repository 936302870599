import { TEST_AVAILABILITY_ENDPOINT } from 'MeetingsLib/constants/endpoints';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'MeetingsLib/utils/utils'. Did ... Remove this comment to see the full error message
import { hubletAwareApi } from 'MeetingsLib/utils/utils';
/**
 * @deprecated do not use this request - endpoint no longer exists
 */
export function postAllAvailability(httpClient, data) {
  return httpClient.post(TEST_AVAILABILITY_ENDPOINT, {
    api: hubletAwareApi,
    withCredentials: false,
    data
  });
}