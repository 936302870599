module.exports = {
  "/": {
    "success": {
      "date picker success": [
        "DATE_PICKER_BUTTON_LOAD"
      ],
      "time picker success": [
        "TIME_PICKER_LOAD"
      ],
      "link not found": [
        "LINK_NOT_FOUND"
      ],
      "email form": [
        "EMAIL_FORM_LOAD"
      ]
    },
    "error": [
      "BOOKING_LOAD_FAILURE"
    ]
  }
};