import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["firstname", "lastname", "email"];
import { Record, Map as ImmutableMap } from 'immutable';
import { FORM_FIELD_TYPES } from 'MeetingsBase/constants/enums';
const defaults = {
  customFormFields: ImmutableMap(),
  email: '',
  firstName: '',
  lastName: ''
};
const constructCustomFormFields = (prefillObj, formFields) => {
  const fields = formFields.reduce((acc, field) => {
    const prefillValue = prefillObj[field.name];
    if (!prefillValue) {
      return acc;
    }
    if (field.fieldType === FORM_FIELD_TYPES.BOOLEAN_CHECKBOX) {
      return acc.set(field.name, JSON.parse(prefillValue));
    } else if (field.fieldType === FORM_FIELD_TYPES.CHECKBOX) {
      return acc.set(field.name, prefillValue.split(';'));
    } else {
      return acc.set(field.name, prefillValue);
    }
  }, ImmutableMap());
  return fields;
};
export default class FormPrefills extends Record(defaults) {
  static from(json, formFields) {
    const {
        firstname,
        lastname,
        email
      } = json,
      rest = _objectWithoutPropertiesLoose(json, _excluded);
    return new FormPrefills({
      firstName: firstname,
      lastName: lastname,
      email,
      customFormFields: constructCustomFormFields(rest, formFields)
    });
  }
  convertToJSON() {
    const {
      customFormFields,
      email,
      firstName,
      lastName
    } = this;
    return {
      customFormFields: customFormFields.toJS(),
      email,
      firstName,
      lastName
    };
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}