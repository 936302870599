import getLangLocale from 'I18n/utils/getLangLocale';
import computeTimezoneGroups from '../helpers/computeTimezoneGroups';
import TimezoneAliases from '../lists/TimezoneAliases';
let cachedTimezoneGroups;
let previousLocale;
export default (() => {
  const currentLocale = getLangLocale();
  if (currentLocale === previousLocale) {
    return cachedTimezoneGroups;
  } else {
    const timezoneAliases = TimezoneAliases();
    previousLocale = currentLocale;
    cachedTimezoneGroups = computeTimezoneGroups(timezoneAliases);
    return cachedTimezoneGroups;
  }
});