import { Record, Map as ImmutableMap } from 'immutable';
import TroubleshootTimesByDurationRecord from './TroubleshootTimesByDuration';
const defaults = {
  hasMore: false,
  offline: false,
  troubleshootTimesByDuration: ImmutableMap({
    0: new TroubleshootTimesByDurationRecord()
  })
};
export default class TroubleshootTimesRecord extends Record(defaults) {
  static from(json) {
    return new TroubleshootTimesRecord({
      hasMore: json.hasMore,
      offline: json.offline,
      troubleshootTimesByDuration: TroubleshootTimesRecord.buildTroubleshootTimesByDurationFromJson(json)
    });
  }
  static buildTroubleshootTimesByDurationFromJson(json) {
    const keys = Object.keys(json.troubleshootTimesByDuration);
    const troubleshootTimesByDurationMap = keys.reduce((map, key) => {
      const troubleshootTimesByDuration = json.troubleshootTimesByDuration[key];
      const troubleshootTimesByDurationRecord = TroubleshootTimesByDurationRecord.from(troubleshootTimesByDuration);
      return map.set(key, troubleshootTimesByDurationRecord);
    }, ImmutableMap());
    return troubleshootTimesByDurationMap;
  }

  /**
   * @deprecated
   * use convertToJSON instead
   */
  toJS() {
    return super.toJS();
  }

  /**
   * @deprecated
   * use convertToJSON instead
   */
  toJSON() {
    return super.toJSON();
  }
  convertToJSON() {
    const typedMap = {};
    const troubleshootTimesByDuration = this.get('troubleshootTimesByDuration');
    const mapKeys = troubleshootTimesByDuration.keySeq().toArray();
    const troubleshootTimesByDurationMap = mapKeys.reduce((map, currentKey) => {
      const troubleshootTimesByDurationValue = new TroubleshootTimesByDurationRecord(troubleshootTimesByDuration.get(currentKey));
      map[currentKey] = troubleshootTimesByDurationValue.convertToJSON();
      return map;
    }, typedMap);
    return {
      hasMore: this.get('hasMore'),
      offline: this.get('offline'),
      troubleshootTimesByDuration: troubleshootTimesByDurationMap
    };
  }

  /**
   * Iterates over the incoming instance's troubleshootTimesByDuration and
   * adds non existing TroublehootTimeInterval to the current instance's
   * Utilizes the .equals of TroubleshootTimeInterval to allow us to work with ImmutableSet
   * @param incomingInstance
   * @returns
   */
  updateWithIncomingInstance(incomingInstance) {
    let existingTroubleshootTimesByDuration = this.get('troubleshootTimesByDuration');
    const incomingTroubleshootTimesByDuration = incomingInstance.get('troubleshootTimesByDuration');
    // convert current to Map<duration, Set<TroubleshootTimeInterval>>
    let currentDurationsByTroubleshootTimesMap = existingTroubleshootTimesByDuration.keySeq().reduce((map, current) => {
      const durationKey = current;
      const troubleshootTimesList = existingTroubleshootTimesByDuration.get(current).get('troubleshootTimes');
      const troubleShootTimesSet = troubleshootTimesList.toOrderedSet();
      // there are no duplicate keys
      map = map.set(durationKey, troubleShootTimesSet);
      return map;
    }, ImmutableMap());
    incomingTroubleshootTimesByDuration.keySeq().forEach(durationKey => {
      const troubleshootTimes = incomingTroubleshootTimesByDuration.get(durationKey).get('troubleshootTimes');
      if (currentDurationsByTroubleshootTimesMap.has(durationKey)) {
        let currentTroubleshootIntervals = currentDurationsByTroubleshootTimesMap.get(durationKey);
        // adds without duplicates
        troubleshootTimes.forEach(incomingInterval => {
          currentTroubleshootIntervals = currentTroubleshootIntervals.add(incomingInterval);
        });
        currentDurationsByTroubleshootTimesMap = currentDurationsByTroubleshootTimesMap.set(durationKey, currentTroubleshootIntervals);
      }
    });
    existingTroubleshootTimesByDuration.keySeq().forEach(durationKey => {
      const updatedValue = currentDurationsByTroubleshootTimesMap.get(durationKey).toList();
      const updatedTroubleshootTimeByDuration = existingTroubleshootTimesByDuration.get(durationKey).set('troubleshootTimes', updatedValue);
      existingTroubleshootTimesByDuration = existingTroubleshootTimesByDuration.set(durationKey, updatedTroubleshootTimeByDuration);
    });
    return new TroubleshootTimesRecord().set('troubleshootTimesByDuration', existingTroubleshootTimesByDuration).set('hasMore', incomingInstance.get('hasMore')).set('offline', incomingInstance.get('offline'));
  }
}