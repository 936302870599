import { Record, Map as ImmutableMap } from 'immutable';
import { FORM_FIELD_TYPES } from 'MeetingsBase/constants/enums';
function getInitialCustomFieldState(formFields) {
  return formFields.reduce((acc, field) => {
    if (field.fieldType === FORM_FIELD_TYPES.BOOLEAN_CHECKBOX) {
      return acc.set(field.name, false);
    } else if (field.fieldType === FORM_FIELD_TYPES.CHECKBOX) {
      return acc.set(field.name, []);
    } else {
      return acc.set(field.name, null);
    }
  }, ImmutableMap());
}
const defaults = {
  customFormFields: ImmutableMap(),
  email: '',
  firstName: '',
  lastName: '',
  originalFormState: null
};
export default class FormState extends Record(defaults, 'FormState') {
  static from(json) {
    json.customFormFields = json.customFormFields ? ImmutableMap(json.customFormFields) : ImmutableMap();
    const jsonMap = ImmutableMap(json).filter(value => value !== undefined);
    return new FormState(jsonMap);
  }
  static customFieldsFromBookInfo(json) {
    const formFields = json.customParams.formFields;
    const customFormFields = getInitialCustomFieldState(formFields);
    return customFormFields;
  }
  convertToJSON() {
    const {
      customFormFields,
      email,
      firstName,
      lastName,
      originalFormState
    } = this;
    return {
      customFormFields: customFormFields.toJS(),
      email,
      firstName,
      lastName,
      originalFormState: originalFormState ? originalFormState.convertToJSON() : null
    };
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}