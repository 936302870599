import { List } from 'immutable';
export default class TimezoneGroups {
  constructor(val) {
    this.groups = List(val);
  }
  groupForMomentTimezone(timezone) {
    return this.groups.find(g => g.timezones.some(tz => tz.momentTimezones.includes(timezone)));
  }
  resolve(timezone, defaultTz) {
    if (timezone === 'Europe/Kyiv') {
      timezone = 'Europe/Kiev';
    }
    let groupForTimezone = this.groupForMomentTimezone(timezone);
    if (groupForTimezone === undefined && defaultTz) {
      groupForTimezone = this.groupForMomentTimezone(defaultTz);
      timezone = defaultTz;
    }
    return groupForTimezone.timezoneForMomentTimezone(timezone).identifier();
  }
}