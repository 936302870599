import { List, Range } from 'immutable';
import TimeRange from 'MeetingsBase/models/TimeRange';
import { FIFTEEN_MINUTE_MILLIS } from 'MeetingsBase/constants/dates';
export function unify(listTimeRanges) {
  return listTimeRanges.sort((x, y) => x.start - y.start).reduce((xs, x) => xs.butLast().concat(xs.size && xs.last().union(x) || [x]), List());
}
export function unifyAll(...arrTimeRanges) {
  return unify(List(arrTimeRanges).flatten(true));
}
export function intersect(listTimeRangesA, listTimeRangeB) {
  return listTimeRangesA.map(timeRangeA => listTimeRangeB.map(timeRangeB => timeRangeB.intersect(timeRangeA)).filter(x => x !== null)).flatten(true);
}
export function intersectAll(listTimeRangesA, listTimeRangesB, ...restArr) {
  if (!listTimeRangesA) {
    return List();
  }
  if (!listTimeRangesB) {
    return unify(listTimeRangesA);
  }
  const intersection = intersect(listTimeRangesA, listTimeRangesB);
  if (restArr.length === 0) {
    return intersection;
  }
  return intersectAll(intersection, restArr[0], ...restArr.slice(1));
}
export function negate(listTimeRanges, start = 0, end = Infinity) {
  return listTimeRanges.sort((x, y) => x.start - y.start).reduce((acc, timeRange) => (acc.butLast() || List()).push(acc.last().set('end', timeRange.start)).push(acc.last().set('start', timeRange.end)), List([TimeRange.from({
    start,
    end
  })])).filter(timeRange => timeRange.start < timeRange.end);
}
export function subtract(listTimeRangesA, listTimeRangesB) {
  return intersect(listTimeRangesA, negate(listTimeRangesB));
}
export function timeRangesOfIntersection(timeRange, listTimeRanges) {
  /**
   * Returns the TimeRanges in listTimeRanges that intersect with timeRange
   * @param {TimeRange} timeRange - The timeRange to check intersections against
   * @param {List} listTimeRanges - A list of TimeRanges where intersections
   * can occur
   * @return {List} A List of TimeRanges filtered from listTimeRanges that
   * intersect with the timeRange specified in the first parameter
   */
  return listTimeRanges.reduce((acc, value) => value.hasIntersection(timeRange) ? acc.push(value) : acc, List());
}
export function treeshakenRemove() {
  return 1;
}
export function addBufferTime(listTimeRanges, bufferTime) {
  return listTimeRanges.map(timeRange => TimeRange.from({
    start: timeRange.start - bufferTime,
    end: timeRange.end + bufferTime
  }));
}
export function getBufferTimeRanges(busyTimeRange, bufferTime) {
  return List([TimeRange.from({
    start: busyTimeRange.start - bufferTime,
    end: busyTimeRange.start
  }), TimeRange.from({
    start: busyTimeRange.end,
    end: busyTimeRange.end + bufferTime
  })]);
}
function getPossibleStartTimes(timeRange, interval) {
  return Range(0, Math.ceil(timeRange.duration() / interval)).map(i => timeRange.start + i * interval).toList();
}
export function roundTimeUp(time, roundTo) {
  /**
   * @param {int} time A time to round, in milliseconds
   * @param {int} roundTo The interval to which to round the nearest time up
   * @returns {int} The time, rounded up to the nearest roundTo interval
   * Example: 9:15, rounded up to the nearest 10 minute interval: 9:20
   */
  return Math.ceil(time / roundTo) * roundTo;
}
function roundStartTime(timeRange, startTimeInterval) {
  const roundTo = startTimeInterval < FIFTEEN_MINUTE_MILLIS ? startTimeInterval : FIFTEEN_MINUTE_MILLIS;
  const roundedStartTime = roundTimeUp(timeRange.start, roundTo);
  return timeRange.clipStart(roundedStartTime);
}
function getStartTimesForRange(timeRange, duration, startTimeInterval = FIFTEEN_MINUTE_MILLIS) {
  /**
   * @param {TimeRange} timeRange Time range to convert to regular start times
   * @param {milliseconds} duration The meeting length in milliseconds
   * @param {milliseconds} startTimeInterval The start time interval in milliseconds
   * @returns {List(timestamp)} All timestamps, at clean 15-minute intervals
   * (:00, :15, :30, :45) at which meetings of the given duration can start
   * and will have time to run to completion
   */
  return getPossibleStartTimes(timeRange, startTimeInterval).filter(startTime => timeRange.end - startTime >= duration);
}
function timeRangeExists(timeRange) {
  return timeRange !== null;
}
export function getFlexibleIntervalStartTimes(listTimeRanges, duration, startTimeInterval = FIFTEEN_MINUTE_MILLIS) {
  /**
   * @param {List(TimeRange)} listTimeRanges Time ranges to convert to start times
   * @param {milliseconds} duration The meeting length in milliseconds
   * @param {milliseconds} startTimeInterval The start time interval in milliseconds
   * @returns {List(timestamp)} All timestamps, at clean 15-minute intervals
   * (:00, :15, :30, :45) at which meetings of the given duration can start
   * and will have time to run to completion
   */
  const roundedTimeRanges = listTimeRanges.map(timeRange => roundStartTime(timeRange, startTimeInterval)).filter(timeRangeExists);
  return roundedTimeRanges.map(timeRange => getStartTimesForRange(timeRange, duration, startTimeInterval)).flatten(true).toSet().toList();
}