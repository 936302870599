import I18n from 'I18n';
import { Range, List, OrderedSet } from 'immutable';
import get from 'transmute/get';
import identity from 'transmute/identity';
import * as TimeRangeListUtils from 'MeetingsBase/utils/TimeRangeListUtils';
import { CUSTOM_AVAILABILITY } from 'MeetingsBase/constants/dates';
import TimeRange from 'MeetingsBase/models/TimeRange';
import enviro from 'enviro';
import { getFullUrl } from 'hubspot-url-utils';
import formatName from 'I18n/utils/formatName';
export function makeDateTime({
  time,
  date,
  timezone
}) {
  return I18n.moment.tz(I18n.moment(date).add(time).format('YYYY-MM-DD HH:mm:ss'), timezone).valueOf();
}
export function timeRangeToDateTimeRange({
  timeRange,
  date,
  timezone
}) {
  return TimeRange.from({
    start: makeDateTime({
      time: timeRange.start,
      date,
      timezone
    }),
    end: makeDateTime({
      time: timeRange.end,
      date,
      timezone
    })
  });
}
export function formatRoundRobinAvailability(availableTimes, allBusyTimes) {
  /**
   * Splits the availability in round robin links when all users in the link
   * have a conflict for a given bookable time slot
   * @param {List} availableTimes - A list of unified TimeRanges that can be booked
   * @param {List} allBusyTimes - A list of the busy times for each
   * user on the link. List([List([TimeRange, ...]), ...])
   * @return {List} A TimeRanges split by user availability
   */
  return availableTimes.reduce((acc, value) => {
    const busyTimeIntersections = allBusyTimes.map(userBusyTimes => TimeRangeListUtils.timeRangesOfIntersection(value, userBusyTimes));
    if (busyTimeIntersections.every(busyTimes => busyTimes.size > 0)) {
      // If all users have a conflict in this bookable time slot, split up the
      // time slot using the availability of each user separately.
      return acc.concat(busyTimeIntersections.map(busyTimes => TimeRangeListUtils.negate(busyTimes, value.get('start'), value.get('end'))).flatten(true));
    }
    return acc.push(value);
  }, List());
}
export function getListOfDays(startTime, daysToAdvertise) {
  /**
   * @param {moment} startTime - Timestamp to start counting days from
   * @param {int} daysToAdvertise - Number of days to list
   * @return {List} List of moments, at the start of each day, at the same
   * time each day, exactly one day apart
   */
  return Range().take(daysToAdvertise).toList().map(numDays => {
    return startTime.clone().add(numDays, 'days');
  });
}
export function calculateBookableDaysFromRollingDateRange(rollingDateRange, now) {
  const rollingDateUnit = rollingDateRange.get('rollingDateUnit');
  const rollingDateValue = rollingDateRange.get('rollingDateValue');
  const offlineDays = rollingDateRange.get('offlineDays');
  const startDate = I18n.moment(now).utc().day(0);
  startDate.startOf('day');
  let daysToAdvertise = rollingDateValue;
  if (rollingDateUnit === 'WEEKS') {
    daysToAdvertise = rollingDateValue * 7;
  }
  const listOfDays = getListOfDays(startDate, daysToAdvertise);
  // offlineDays will be populated for business days
  if (offlineDays.length) {
    // convert offlineDay strings to Moment offsets
    const offlineDaysInMoments = OrderedSet.of(...offlineDays.map(offlineDay => I18n.moment(now).day(offlineDay).day()));
    const listOfDaysWithoutOfflineDays = listOfDays.filter(currentMoment => !offlineDaysInMoments.has(currentMoment.day())).toList();
    return listOfDaysWithoutOfflineDays;
  }
  return listOfDays;
}
export function getBookableDays(weeksToAdvertise, customAvailabilityStartDate, customAvailabilityEndDate, now, rollingDateRange) {
  /**
   * @param {int} weeksToAdvertise - Number of weeks ahead a meeting can be booked
   * @param {int} customAvailabilityStartDate - Date custom availability starts
   * @param {int} customAvailabilityEndDate - Date custom availability ends
   * @param {int} now - Timestamp representing this moment
   * @return {List} List of moments, at the start of each available day
   * in UTC timezone
   */
  if (rollingDateRange && rollingDateRange.get('rollingDateValue') > 0) {
    return calculateBookableDaysFromRollingDateRange(rollingDateRange, now);
  }
  const hasCustomAvailability = weeksToAdvertise === CUSTOM_AVAILABILITY;
  const daysToAdvertise = hasCustomAvailability ? I18n.moment.utc(customAvailabilityEndDate).diff(I18n.moment.utc(customAvailabilityStartDate), 'days') + 1 : weeksToAdvertise * 7;
  const startDate = hasCustomAvailability ? I18n.moment.utc(customAvailabilityStartDate) : I18n.moment(now).utc().day(0);
  startDate.startOf('day');
  return getListOfDays(startDate, daysToAdvertise);
}
export function availabilityRecordToDateTimeRangesFromStart(availability, bookableDays, timezone) {
  /**
   * Converts the user's configured weekly availability to a List of TimeRanges
   * representing epoch timestamps of availability for a given list of days
   * @param {AvailabilityRecord} availability - A map of weekdays to configured availability
   * for that day, expressed as minutes from the start of the day
   * @param {List} bookableDays - List of days on which meetings can be booked
   * @param {string} timezone - Configured timezone on meeting link
   * @param {int} now - Timestamp representing this moment
   * @return {List} List of TimeRange, representing availability as configured as
   * epoch timestamps for the given list of days
   */

  return bookableDays.map(date =>
  // transform static configured availablity for a day -> a list of epoch timeranges
  availability.getForDateString(date).map(timeRange => timeRangeToDateTimeRange({
    timeRange,
    date,
    timezone
  }))).flatten(true).filter(identity);
}
export function getConfiguredAvailableTimeRanges(availability, weeksToAdvertise, customAvailabilityStartDate, customAvailabilityEndDate, timezone, now, rollingDateRange) {
  /**
   * Converts the user's configured weekly availability to a List of TimeRanges
   * starting from the proper start date, accounting for custom availability.
   * @param {AvailabilityRecord} availability - A map of weekdays to configured availability
   * for that day
   * @param {int} weeksToAdvertise - Number of weeks ahead a meeting can be booked
   * @param {int} customAvailabilityStartDate - Date custom availability starts
   * @param {int} customAvailabilityEndDate - Date custom availability ends
   * @param {string} timezone - Configured timezone on meeting link
   * @param {int} now - Timestamp representing this moment
   * @return {List} List of TimeRange, starting from the correct start date, in the user's
   * configured timezone, representing available times as configured.
   */
  const bookableDays = getBookableDays(weeksToAdvertise, customAvailabilityStartDate, customAvailabilityEndDate, now, rollingDateRange);
  return availabilityRecordToDateTimeRangesFromStart(availability, bookableDays, timezone);
}
export function isQA() {
  return enviro.getInternal('api') === 'qa';
}
export function getEnvPostfix() {
  return isQA() ? 'qa' : '';
}
export function getAnalyticsScriptSource(portalId, embedded) {
  const qaPostfix = isQA() ? 'qa' : 'prod';
  const disableCollectedFormsQueryParam = 'disableCollectedForms=true';
  const embeddedQueryParam = embedded ? `?analyticsCommon=true&${disableCollectedFormsQueryParam}` : `?${disableCollectedFormsQueryParam}`;
  const url = `${getFullUrl('js', {
    domainOverride: 'hs-scripts',
    envOverride: qaPostfix
  })}/${portalId}.js${embeddedQueryParam}`;
  return url;
}
export function getAnalyticsCanonicalUrl() {
  const origin = window.location.origin || `${window.location.protocol}//${window.location.hostname}`;
  return `${origin}${window.location.pathname}`;
}
export function getVisibleUserTag(userProfile) {
  const formattedName = formatName({
    firstName: get('firstName', userProfile),
    lastName: get('lastName', userProfile)
  });
  return formattedName || get('email', userProfile);
}