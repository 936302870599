export const PUBLIC_API_ENDPOINT_V1 = `meetings-public/v1`;
export const PUBLIC_API_ENDPOINT_V2 = `meetings-public/v2`;
export const PUBLIC_API_ENDPOINT_V3 = `meetings-public/v3`;
export const PUBLIC_BOOK_ENDPOINT = `${PUBLIC_API_ENDPOINT_V1}/book`;
export const PUBLIC_BOOK_ENDPOINT_V2 = `${PUBLIC_API_ENDPOINT_V2}/book`;
export const PUBLIC_BOOK_ENDPOINT_V3 = `${PUBLIC_API_ENDPOINT_V3}/book`;
export const LINK_ENDPOINT = `${PUBLIC_API_ENDPOINT_V1}/link`;
/**
 * @deprecated endpoint no longer exists
 */
export const TEST_AVAILABILITY_ENDPOINT = `${PUBLIC_API_ENDPOINT_V1}/test-availability`;
export const BRANDING_ENDPOINT = 'branding/v1';
export const TROUBLESHOOT_ENDPOINT = `${PUBLIC_API_ENDPOINT_V1}/troubleshoot`;
export const TROUBLESHOOT_ENDPOINT_V2 = `${PUBLIC_API_ENDPOINT_V2}/troubleshoot`;
export const TROUBLESHOOT_ENDPOINT_V3 = `${PUBLIC_API_ENDPOINT_V3}/troubleshoot`;
export const FAILURE_ENDPOINT = 'meetings-booking-failure/v1/booking-failure';
export const EMAIL_ENDPOINT = `${PUBLIC_API_ENDPOINT_V1}/email`;