'use es6';

import { createSelector } from 'reselect';
import { getEvent, getShouldAutoSubmit, getCanAddGuests } from 'MeetingsLib/selectors/BookInfoSelectors';
import { getStagedFormFieldValidation, getInitialFormFieldValidation, getFormFieldValidation } from 'MeetingsLib/selectors/FormFieldValidationSelector';
import { getAllRequiredConsentCollected } from './LegalConsentFormSelectors';
import get from 'transmute/get';
const forcePropertyFormIfInitialFormComplete = get('forcePropertyForm');
const checkAllFormValidation = formStateValidation => {
  if (!formStateValidation) {
    return false;
  }
  const customFieldsInvalid = formStateValidation.get('customFormFields').filter(fieldValidation => {
    return Boolean(fieldValidation);
  }).size > 0;
  const isEmailInvalid = Boolean(formStateValidation.get('email'));
  const isFirstNameInvalid = Boolean(formStateValidation.get('firstName'));
  const isLastNameInvalid = Boolean(formStateValidation.get('lastName'));
  return !(customFieldsInvalid || isEmailInvalid || isFirstNameInvalid || isLastNameInvalid);
};
export const canSubmitInitialPropertyForm = createSelector([getInitialFormFieldValidation, forcePropertyFormIfInitialFormComplete, getShouldAutoSubmit, getCanAddGuests], (formFieldValidation, forcePropertyForm, shouldAutoSubmit, canAddGuests) => {
  return (forcePropertyForm === false || shouldAutoSubmit && !canAddGuests) && checkAllFormValidation(formFieldValidation);
});
export const canSubmitStagedPropertyForm = createSelector([getStagedFormFieldValidation], checkAllFormValidation);
export const canSubmitPropertyForm = createSelector([getFormFieldValidation], checkAllFormValidation);
export const canSubmitMeetingTime = createSelector([getEvent], event => Boolean(event.dateTime));
export const canSubmitBooking = createSelector([canSubmitMeetingTime, canSubmitPropertyForm, getAllRequiredConsentCollected], (meetingTimeValid, propertyFormValid, legalConsentValid) => meetingTimeValid && propertyFormValid && legalConsentValid);