import { getFullUrl } from 'hubspot-url-utils';
import Raven from 'raven-js';
export const getBaseUrl = loadBalancer => {
  const hubletOverride = window.hubspot && window.hubspot.meetingsHubletOverride;
  return getFullUrl(loadBalancer || 'app', hubletOverride ? {
    hubletOverride
  } : {});
};
export const buildEmbeddedPaymentSessionUrl = (sessionId, email, loadBalancer) => {
  if (!sessionId) {
    return null;
  }
  const url = `${getBaseUrl(loadBalancer)}/payments/iframe/purchase/${sessionId}?referrer=PAYMENT_LINK_MEETING&email=${email}`;
  return url;
};

/**
 *
 * @param checkoutUrl
 * @param email
 * parses out payment slug from checkoutUrl and builds a new one with the /iframe route
 * For instance https://app.hubspotqa.com/payments/NT_geLoqR?referrer=PAYMENT_LINK_MEETING
 */
export const buildEmbeddedCheckoutUrl = (checkoutUrl, email, loadBalancer) => {
  const parsedSlug = checkoutUrl.split('?')[0].split('payments/')[1];
  const url = `${getBaseUrl(loadBalancer)}/payments/iframe/${parsedSlug}?referrer=PAYMENT_LINK_MEETING&email=${email}`;
  return url;
};
export const unexpectedMessageHandler = message => {
  Raven.captureMessage('Unexpected Embedded Paid Meetings PostMessage ', {
    extra: {
      message: JSON.stringify(message)
    },
    fingerprint: ['PaymentIframe']
  });
};