'use es6';

import { isValid } from '../../core/SimpleDate';

/**
 * @param {SimpleDate} date
 * @returns {Object} An object of the form { year, month }
 */
export const getPageFromDate = date => {
  if (!isValid(date)) return null;
  return {
    year: date.year,
    month: date.month
  };
};

/**
 * @param {Object} calendarPage An object of the form `{ year, month }`
 * @returns {Object} An object of the form `{ year, month }` for the previous month
 */
export const prevCalendarPage = ({
  month,
  year
}) => {
  const newMonth = (month + 11) % 12;
  const newYear = newMonth === month - 1 ? year : year - 1;
  return {
    month: newMonth,
    year: newYear
  };
};

/**
 * @param {Object} calendarPage An object of the form `{ year, month }`
 * @returns {Object} An object of the form `{ year, month }` for the next month
 */
export const nextCalendarPage = ({
  month,
  year
}) => {
  const newMonth = (month + 1) % 12;
  const newYear = newMonth === month + 1 ? year : year + 1;
  return {
    month: newMonth,
    year: newYear
  };
};