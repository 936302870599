import enviro from 'enviro';
/**
 * Adds Failure Injection Headers in QA only via localStorage flags
 * For specific slugs that are prefixed with meetings-booking-failure
 */
const HTTP_FAILURE_INJECTION_KEY = 'HTTP_FAILURE_INJECTION';

/** The Java agent fails to parse the rate if its note passed as a float */
export const rateToString = rate => rate === 1 ? '1.0' : String(rate);
const buildFailureInjectionHeader = injectionConfig => `${injectionConfig.type};${injectionConfig.scope};ABORT;1;${rateToString(injectionConfig.rate)};${injectionConfig.sleep}`;
export const triggerFailureInjectionViaHeaders = (slug = '') => {
  const isQA = enviro.isQa();
  const isFailureSlug = slug.includes('meetings-booking-failure');
  if (isQA && isFailureSlug) {
    try {
      const httpFailureInjectionValue = window.localStorage.getItem(HTTP_FAILURE_INJECTION_KEY) || '';
      const injectionConfig = JSON.parse(httpFailureInjectionValue);
      const headerValue = buildFailureInjectionHeader(injectionConfig);
      return {
        'x-hubspot-failure-injection': headerValue
      };
    } catch (err) {
      console.log(err);
    }
  }
  return {};
};