import { registerQuery } from 'data-fetching-client';
import { fetchTroubleshootTimesPage, fetchV3TroubleshootTimes } from 'MeetingsLib/api/troubleshootAPI';
export const fetchV3TroubleshootTimesQuery = registerQuery({
  fieldName: 'troubleshootTimes',
  args: ['slug', 'now'],
  fetcher: fetchV3TroubleshootTimes
});
export const fetchTroublshootTimesPageQuery = registerQuery({
  fieldName: 'troubleshootTimesPage',
  args: ['slug', 'monthOffset', 'timezone'],
  fetcher: fetchTroubleshootTimesPage
});