import { Record } from 'immutable';
const defaults = {
  title: null,
  description: null,
  logoUrl: null,
  useCompanyLogo: false,
  showWelcomeScreen: false
};
export default class WelcomeScreenInfo extends Record(defaults) {
  static from(json) {
    return new WelcomeScreenInfo(json);
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}