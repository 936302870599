// @ts-expect-error untyped import
import { combineActions, handleActions } from 'flux-actions';
import Branding from 'MeetingsBase/models/Branding';
// @ts-expect-error untyped import
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
const initialState = new Branding();
export default handleActions({
  [combineActions(ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED, ActionTypes.PUBLIC_AVAILABILITY_INFO_OWNER_FETCH_SUCCEEDED)](state, action) {
    if (action.payload && action.payload.branding && action.payload.branding.primaryColor !== null) {
      const {
        primaryColor,
        logoUrl,
        logoAltText,
        logoHeight,
        logoWidth,
        companyName
      } = action.payload.branding;
      return state.set('primaryColor', primaryColor).set('logoUrl', logoUrl).set('logoAltText', logoAltText).set('logoHeight', logoHeight).set('logoWidth', logoWidth).set('companyName', companyName);
    }
    return state;
  }
}, initialState);