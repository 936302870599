import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error untyped import
import { EXCLUDED_FORM_FIELDS } from 'MeetingsLib/constants/enums';
export const mapFalsyParamsToNull = url => {
  const queryParams = new URLSearchParams(url.search);
  const params = Object.fromEntries(queryParams);
  return Object.keys(params).reduce((acc, key) => Object.assign({}, acc, {
    [key]: params[key] || null
  }), {});
};
export const constructFormState = formFieldUrlParams => {
  let firstName;
  let lastName;
  let email;
  const paramsAsMap = ImmutableMap(formFieldUrlParams);
  const customFormFields = paramsAsMap.reduce((acc, val, originalKey) => {
    const key = originalKey.toLowerCase();
    if (key === 'firstname') {
      firstName = val;
    } else if (key === 'lastname') {
      lastName = val;
    } else if (key === 'email') {
      email = val;
    } else if (EXCLUDED_FORM_FIELDS.indexOf(key) === -1) {
      acc[originalKey] = val;
    }
    return acc;
  }, {});
  return {
    firstName,
    lastName,
    email,
    customFormFields
  };
};