import UserMessage from 'MeetingsLib/models/UserMessage';
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
export function showUserMessage({
  content,
  type
}) {
  return {
    type: ActionTypes.SHOW_USER_MESSAGE,
    // @ts-expect-error fix type
    userMessage: new UserMessage({
      content,
      type
    })
  };
}
export function toggleTroubleshootMode(active) {
  return {
    type: ActionTypes.TOGGLE_TROUBLESHOOT_MODE,
    active
  };
}