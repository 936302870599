import { Record } from 'immutable';
const defaults = {
  ab: null,
  abStatus: null,
  contentId: null
};
export default class ABTestingParams extends Record(defaults, 'ABTestingParams') {
  static from(json) {
    return new ABTestingParams(json);
  }
  compact() {
    // @ts-expect-error mismatched api
    return this.reduce((truthyFields, fieldValue, fieldName) => {
      if (fieldValue) {
        truthyFields[fieldName] = fieldValue;
      }
      return truthyFields;
    }, {});
  }
}