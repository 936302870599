import { VIRAL_LINK_POST_MESSAGES } from '../constants/EmbeddedViralLinks';
const postMessageHostIsHubSpot = event => /https:\/\/(app|local)\.hubspot(qa)?\.com/.test(event.origin);
export const onIframeResizeMessage = ({
  iframeRef,
  onMessage
}) => {
  const iframeContentWindow = iframeRef && iframeRef.current && iframeRef.current.contentWindow;
  window.addEventListener('message', event => {
    if (postMessageHostIsHubSpot(event) && event.source === iframeContentWindow && event.data.action === VIRAL_LINK_POST_MESSAGES.SET_IFRAME_HEIGHT) {
      onMessage(event.data.value);
    }
  });
};