import { Record } from 'immutable';
const defaults = {
  communicationTypeId: null,
  consented: false,
  required: false
};
export default class LegalCommunicationConsent extends Record(defaults, 'LegalCommunicationConsent') {
  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}