import I18n from 'I18n';
import { MAX_CUSTOM_AVAILABILITY_WEEKS, DAY_DURATION } from 'MeetingsBase/constants/dates';
import formatRelativeDuration from 'I18n/utils/formatRelativeDuration';
export const buildTimeStringFromMs = timeInMS => {
  return formatRelativeDuration(I18n.moment.duration(timeInMS), {
    combined: true
  });
};
export const parseDuration = (hourString, minuteString) => {
  const hours = hourString || '0';
  const minutes = minuteString || '0';
  const duration = I18n.moment.duration({
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10)
  });
  return duration.asMilliseconds();
};
export function isBeforeCustomAvailabilityPeriod(todayUTC, startDate) {
  return startDate && todayUTC.isSameOrBefore(startDate);
}
export function isDuringCustomAvailabilityPeriod(todayUTC, startDate, endDate) {
  return startDate && endDate && todayUTC.isAfter(startDate) && todayUTC.isSameOrBefore(endDate);
}
export function isAfterCustomAvailabilityPeriod(todayUTC, endDate) {
  return !!(endDate && todayUTC.isAfter(endDate));
}
export function isCustomAvailabilityDurationValid(startDate, endDate) {
  // Add one because availability is inclusive of end date
  const daysOfAvailability = I18n.moment.utc(endDate).diff(I18n.moment.utc(startDate), 'days') + 1;
  return daysOfAvailability > 0 && daysOfAvailability <= MAX_CUSTOM_AVAILABILITY_WEEKS * 7 + 1;
}
export function isCustomAvailabilityPeriodValid(startDate, endDate) {
  if (!startDate || !endDate) {
    return false;
  }
  return isCustomAvailabilityDurationValid(startDate, endDate);
}
export function isDateInPast(date, now, userTimezone) {
  // if the last moment in a day is before now, that day is in the past
  const dateMoment = I18n.moment.tz(date, userTimezone).endOf('day');
  const nowMoment = I18n.moment(now);
  return dateMoment.isBefore(nowMoment);
}
export function validTimeRange({
  start,
  end
}) {
  const startNotBelowZero = start >= 0;
  const endNotAboveMax = end <= DAY_DURATION;
  return startNotBelowZero && endNotAboveMax && start < end;
}