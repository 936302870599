import { fetchTroubleshootTimesRequest, fetchV3TroubleshootTimes } from '../../api/troubleshootAPI';
import noPortalApiClient from 'hub-http/clients/noPortalApiClient';
import getIn from 'transmute/getIn';
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
import { getIsUngatedForAvailabilityDaysLimit
// @ts-expect-error no type declarations
} from 'MeetingsLib/selectors/BookInfoSelectors';
export const requestTroubleshootTimes = () => ({
  type: ActionTypes.REQUEST_TROUBLESHOOT_TIMES
});
export const receiveTroubleshootTimes = data => ({
  type: ActionTypes.RECEIVE_TROUBLESHOOT_TIMES,
  data
});
export const requestTroubleshootTimesRejected = err => ({
  type: ActionTypes.REQUEST_TROUBLESHOOT_TIMES_REJECTED,
  err
});
export const updateTroubleshootTimes = data => ({
  type: ActionTypes.UPDATE_TROUBLESHOOT_TIMES,
  data
});
export const fetchTroubleshootTimes = () => (dispatch, getState) => {
  const slug = getState().userSlug;
  const timezone = getState().userTimezone;
  const contactEmail = getState().emailFormState;
  const portalId = getIn(['bookInfo', 'portalId'])(getState());
  const isUngatedForAvailabilityDaysLimit = getIsUngatedForAvailabilityDaysLimit(getState());
  dispatch(requestTroubleshootTimes());
  if (isUngatedForAvailabilityDaysLimit) {
    return fetchV3TroubleshootTimes({
      // @ts-expect-error fix type
      slug,
      // @ts-expect-error fix type
      portalId,
      // @ts-expect-error fix type
      timezone,
      contactEmail
    }).then(response => dispatch(receiveTroubleshootTimes(response))).catch(err => dispatch(requestTroubleshootTimesRejected(err)));
  }
  // @ts-expect-error fix type
  return fetchTroubleshootTimesRequest(noPortalApiClient, slug, portalId).then(response => dispatch(receiveTroubleshootTimes(response))).catch(err => dispatch(requestTroubleshootTimesRejected(err)));
};