import { useQuery } from 'data-fetching-client';
import { fetchAvailabilityPageQuery, fetchV3PublicAvailabilityInfoQuery } from '../query/bookingAvailabilityQuery';
export const useBookingAvailabilityInfo = (params, shouldSkip) => {
  const {
    slug
  } = params;
  const skip = shouldSkip || !slug;
  const {
    data,
    loading,
    refetch,
    error
  } = useQuery(fetchV3PublicAvailabilityInfoQuery, {
    variables: Object.assign({}, params),
    fetchPolicy: 'cache-and-network',
    skip
  });
  const publicAvailabilityInfo = data && data.publicAvailabilityInfo;
  return {
    publicAvailabilityInfo,
    loading,
    refetch,
    error
  };
};
export const useBookingAvailabilityPage = ({
  monthOffset,
  slug,
  shouldSkip,
  hubspotUtk,
  email,
  meetingChangeId,
  timezone
}) => {
  const skip = shouldSkip || !slug || monthOffset < 0 || monthOffset > 14;
  const {
    data,
    error,
    loading,
    refetch,
    previousData
  } = useQuery(fetchAvailabilityPageQuery, {
    variables: {
      monthOffset,
      slug,
      hubspotUtk,
      email,
      meetingChangeId,
      timezone
    },
    fetchPolicy: 'cache-first',
    skip
  });
  const hasMore = data && data.publicAvailabilityPage.linkAvailability.hasMore;
  return {
    availabilityPage: data && data.publicAvailabilityPage,
    loading,
    error,
    refetch,
    previousData,
    hasMore
  };
};