import { Record } from 'immutable';
import Event from 'MeetingsLib/models/Event';
const defaults = {
  event: new Event(),
  postResponse: null
};
export default class BookingLifecycle extends Record(defaults, 'BookingLifecycleRecord') {
  convertToJSON() {
    const {
      event,
      postResponse
    } = this;
    return {
      event: event.toJSON(),
      postResponse: postResponse ? Object.assign({
        bookedOffline: postResponse.bookedOffline
      }, postResponse.contact ? {
        contact: postResponse.contact.toJSON()
      } : {}, postResponse.organizer ? {
        organizer: postResponse.organizer.toJSON()
      } : {}, {
        timerange: postResponse.timerange.toJSON()
      }) : null
    };
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}