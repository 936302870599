'use es6';

import { createSelector } from 'reselect';
import get from 'transmute/get';
import I18n from 'I18n';
import { shouldIncludeDeleted, formatCustomFormFields, getRoundRobinUserIdToBook } from 'MeetingsLib/utils/utils';
import { getHubSpotUtk } from 'MeetingsLib/utils/analytics';
import { LINK_TYPES, MEETING_TYPES } from 'MeetingsBase/constants/enums';
import { getRecaptchaEnabled, getUserTimezone } from './BookInfoSelectors';
import { getActiveLocale } from './LocalizationSelectors';
import { getPaymentSessionId, getPaymentInfoForBE } from './PaymentStatusSelectors';
export const getSlug = state => get('userSlug', state);
export const getCurrentTime = get('currentTime');
export const getEmbedded = get('embedded');
export const getMeetingChangeId = get('meetingChangeId');
const getParentPageHSAnalyticsInfo = get('parentPageHSAnalyticsInfo');
function getUtkToPass(embedded, parentPageHSAnalyticsInfo) {
  if (!parentPageHSAnalyticsInfo) {
    return getHubSpotUtk();
  }
  const {
    parentHubspotUtk
  } = parentPageHSAnalyticsInfo;
  return embedded && parentHubspotUtk ? parentHubspotUtk : getHubSpotUtk();
}
function addParentCosPageUrl(params, parentPageHSAnalyticsInfo) {
  if (!parentPageHSAnalyticsInfo) {
    return params;
  }
  const parentCosPageUrl = parentPageHSAnalyticsInfo.parentPageUrl;
  if (parentCosPageUrl) {
    return Object.assign({}, params, {
      parentCosPageUrl
    });
  }
  return params;
}
function addABTestingParams(params, abTestingParams) {
  if (!abTestingParams) {
    return params;
  }
  const {
    ab,
    abStatus,
    contentId
  } = abTestingParams;
  if (ab && abStatus && contentId) {
    return Object.assign({}, params, {
      ab,
      abStatus,
      contentId
    });
  }
  return params;
}
export const getFetchAvailabilityQueryParams = params => {
  return createSelector([getSlug, getCurrentTime, getEmbedded, getParentPageHSAnalyticsInfo, getUserTimezone], (slug, now, embedded, parentPageHSAnalyticsInfo, userTimezone) => {
    let requestParams = {
      slug,
      now,
      includeInactiveLink: shouldIncludeDeleted(params),
      hubspotUtk: getUtkToPass(embedded, parentPageHSAnalyticsInfo),
      location: window.location.host,
      timezone: userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    requestParams = addParentCosPageUrl(requestParams, parentPageHSAnalyticsInfo);
    const email = get('email', params);
    if (email) {
      requestParams = Object.assign({}, requestParams, {
        email
      });
    }
    return requestParams;
  });
};
export const getCreateMeetingQueryParams = state => {
  const {
    trackingParams,
    embedded,
    parentPageHSAnalyticsInfo,
    userSlug,
    abTestingParams
  } = state;
  const utk = getUtkToPass(embedded, parentPageHSAnalyticsInfo);
  let requestParams = Object.assign({}, trackingParams.compact(), {
    hubspotUtk: utk,
    slug: userSlug
  });
  requestParams = addABTestingParams(requestParams, abTestingParams);
  requestParams = addParentCosPageUrl(requestParams, parentPageHSAnalyticsInfo);
  return requestParams;
};
export const getCreateMeetingBodyData = ({
  duration,
  dateTime
}) => state => {
  const {
    bookingLifecycle: {
      event
    },
    bookInfo: {
      offline,
      allUsersBusyTimes,
      linkType,
      bufferTime,
      customParams: {
        legalConsentEnabled
      }
    },
    guestEmails,
    legalConsentResponses,
    recaptcha: {
      token: recaptchaToken
    },
    userTimezone,
    formState
  } = state;
  const activeLocale = getActiveLocale(state);
  const recaptchaEnabled = getRecaptchaEnabled(state);
  const paymentSessionId = getPaymentSessionId(state);
  const formFields = formatCustomFormFields(formState.customFormFields);
  const body = {
    firstName: formState.firstName,
    lastName: formState.lastName,
    email: formState.email,
    formFields,
    offline,
    locale: activeLocale || I18n.locale,
    timezone: userTimezone,
    duration,
    startTime: dateTime,
    guestEmails: guestEmails.toJS()
  };
  if (legalConsentEnabled) {
    body.legalConsentResponses = legalConsentResponses.toJS();
  }
  if (recaptchaEnabled) {
    body.recaptchaToken = recaptchaToken;
  }
  if (paymentSessionId) {
    body.paymentsSessionId = paymentSessionId;
    body.payment = getPaymentInfoForBE(state);
  }
  if (linkType === LINK_TYPES.get(MEETING_TYPES.ROUND_ROBIN)) {
    const {
      chosenUserId,
      allUserIds
    } = getRoundRobinUserIdToBook(event, allUsersBusyTimes, bufferTime);
    body.likelyAvailableUserId = chosenUserId;
    if (allUserIds) {
      body.likelyAvailableUserIds = allUserIds;
    }
  }
  return body;
};
export const buildMeetingRescheduleRequestBody = ({
  dateTime,
  duration
}) => state => {
  const meetingRescheduleRequest = {
    startTime: dateTime,
    endTime: dateTime + duration,
    slug: getSlug(state),
    meetingChangeId: getMeetingChangeId(state)
  };
  return meetingRescheduleRequest;
};