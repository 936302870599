import keyMirror from 'react-utils/keyMirror';
export const UNAVAILABLE_REASONS = keyMirror({
  ALL_DAY_EVENT: null,
  EVENT: null,
  BUFFER: null,
  MINIMUM_NOTICE: null,
  PAST: null,
  DATE_NOT_CONFIGURED: null,
  TIME_NOT_CONFIGURED: null,
  TIME_SLOTS_UNAVAILABLE: null,
  OUTSIDE_WORKING_HOURS: null
});

/* In Meetings' certain customer properties are always being set in
 * ContactHelper.createOrUpdateContact. Thus, we shouldn't pass those
 * properties as booking data in formFields.
 * https://git.hubteam.com/HubSpot/Meetings/blob/master/MeetingsService/src/main/java/com/hubspot/meetings/service/book/ContactsHelper.java/#L86
 */
export const EXCLUDED_FORM_FIELDS = ['firstname', 'lastname', 'lifecyclestage'];