import { getAnalyticsScriptSource, getAnalyticsCanonicalUrl } from 'MeetingsBase/utils/utils';
// @ts-expect-error untyped import
import UrlParts from 'PatternValidationJS/regex/parts/UrlParts';
import Raven from 'raven-js';
const {
  domain,
  protocol
} = UrlParts;
const domainRegexStr = `^${protocol}(${domain})`;
export const getUrlDomain = url => {
  const match = url.match(domainRegexStr);
  return match && match[1];
};
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = cookie.substring(name.length + 1);
        break;
      }
    }
  }
  return cookieValue;
}
export function getHubSpotUtk() {
  return getCookie('hubspotutk');
}
export function getCsrfCookie() {
  return getCookie('csrf.app');
}
let __hstc;
let __hssc;
let __hsfp;
export function getHubSpotIdentityInfo() {
  return {
    __hstc,
    __hssc,
    __hsfp
  };
}
function attachEmbedSpecificCallbacks(portalId, parentPageInfo) {
  const {
    pageId,
    folderId,
    contentType,
    contentGroupId
  } = parentPageInfo;
  const setPortalId = ['setPortalId', portalId];
  const setCookiePolicy = ['setCookiePolicy', [{
    enabled: true,
    privacyPolicy: 2,
    privacyDefault: true
  }]];
  const setPageId = ['setPageId', pageId || null];
  const setContentType = ['setContentType', contentType || null];
  const setContentMetadata = ['setContentMetadata', {
    contentPageId: pageId || null,
    contentFolderId: folderId || null,
    contentGroupId: contentGroupId || null
  }];
  window._hsq.push(setPortalId);
  window._hsq.push(setCookiePolicy);
  window._hsq.push(setPageId);
  window._hsq.push(setContentType);
  window._hsq.push(setContentMetadata);
}
export function trackHsFormViews(formGuid, portalId, parentPageInfo, embedded) {
  if (!formGuid) {
    return;
  }
  const canonicalUrl = ['setCanonicalUrl', getAnalyticsCanonicalUrl()];
  const attachFormGuid = ['trackFormView', formGuid, {
    formType: 5
  }];
  window._hsq = window._hsq || [];
  if (embedded) {
    attachEmbedSpecificCallbacks(portalId, parentPageInfo);
  }
  window._hsq.push(canonicalUrl);
  window._hsq.push(attachFormGuid);
}
const scriptId = 'hs-script-loader';
function hasHsTrackingCode() {
  return document.getElementById(scriptId);
}
export function addHsTrackingCode(portalId, embedded) {
  if (hasHsTrackingCode()) {
    return;
  }
  const hsAnalyticsScript = document.createElement('script');
  hsAnalyticsScript.async = true;
  hsAnalyticsScript.id = scriptId;
  hsAnalyticsScript.src = getAnalyticsScriptSource(portalId, embedded);
  document.body.appendChild(hsAnalyticsScript);
  window._hsq = window._hsq || [];
  window._hsq.push(['addIdentityListener', (hstc, hssc, hsfp) => {
    __hstc = hstc;
    __hssc = hssc;
    __hsfp = hsfp;
  }]);
}
export function removeHsTrackingCode() {
  if (!hasHsTrackingCode()) {
    return;
  }
  const hsAnalyticsScript = document.getElementById(scriptId);
  hsAnalyticsScript === null || hsAnalyticsScript === void 0 || hsAnalyticsScript.remove();
  __hstc = undefined;
  __hssc = undefined;
  __hsfp = undefined;
}

// MeetingsLib version is more valuable alongside
// other metrics since MeetingsPublic is rarely deployed
export function trackLibVersion() {
  if (window.hubspot && window.hubspot.bender && window.hubspot.bender.depVersions && window.hubspot.bender.depVersions.MeetingsLib) {
    Raven.setExtraContext({
      meetingsLibVersion: window.hubspot.bender.depVersions.MeetingsLib
    });
  }
}