import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { TROUBLESHOOT_ENDPOINT, TROUBLESHOOT_ENDPOINT_V3 } from 'MeetingsLib/constants/endpoints';
// @ts-expect-error no defined types yet
import { hubletAwareApi } from 'MeetingsLib/utils/utils';
// @ts-expect-error no defined types yet
import { getCsrfCookie } from 'MeetingsLib/utils/analytics';
export const fetchTroubleshootTimesRequest = (httpClient, slug, portalId) => {
  return httpClient.get(`${TROUBLESHOOT_ENDPOINT}`, {
    ignoreRedirect: true,
    query: {
      slug,
      portalId
    },
    api: hubletAwareApi
  });
};
export const fetchV3TroubleshootTimes = params => {
  return noAuthApiClient.get(`${TROUBLESHOOT_ENDPOINT_V3}`, {
    ignoreRedirect: true,
    query: Object.assign({}, params),
    api: hubletAwareApi,
    headers: {
      'X-Hubspot-Csrf-Hubspotapi': getCsrfCookie()
    }
  });
};
export const fetchTroubleshootTimesPage = params => {
  const {
    slug,
    portalId,
    hubspotUtk,
    contactEmail,
    monthOffset,
    timezone
  } = params;
  return noAuthApiClient.get(`${TROUBLESHOOT_ENDPOINT_V3}/availability-page`, {
    query: {
      slug,
      portalId,
      hubspotUtk,
      contactEmail,
      monthOffset,
      timezone
    },
    api: hubletAwareApi,
    headers: {
      'X-Hubspot-Csrf-Hubspotapi': getCsrfCookie()
    }
  });
};