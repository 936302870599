import { Record } from 'immutable';
const defaults = {
  createdAt: null,
  createdByUserId: null,
  name: null,
  ordering: null,
  portalId: null
};
export default class ActivityType extends Record(defaults) {
  static from(json) {
    return new ActivityType(json);
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}