import { Record } from 'immutable';
const defaults = {
  avatar: null,
  companyAvatar: null,
  displayAvatarOption: null,
  headline: null,
  publicDisplayAvatarOption: null
};
export default class DisplayInfo extends Record(defaults) {
  static from(json) {
    return new DisplayInfo(json);
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}