import { I18nInternal as I18n } from '../../internal/i18n-internal';
export function initAppTranslations(translations) {
  return Promise.all(translations).then(result => {
    try {
      const Raven = window.Raven;
      window.performance.mark('mark_i18n_load_successful');
      window.performance.measure('measure_i18n_load_time', undefined, 'mark_i18n_load_successful');
      const i18nDuration = window.performance.getEntriesByName('measure_i18n_load_time')[0].duration;
      if (i18nDuration && Raven) {
        Raven.setExtraContext({
          i18nTime: i18nDuration
        });
      }
      const i18nLangLocale = I18n.locale !== 'en-us' && !I18n.langEnabled && !I18n.publicPage ? 'en-us' : I18n.locale;
      if (Raven) {
        Raven.setExtraContext({
          i18nLangLocale,
          i18nDateNumFormat: I18n.locale,
          isNewI18nLoader: true
        });
      }
    } catch (e) {
      return result;
    }
    return result;
  });
}