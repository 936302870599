import { Record } from 'immutable';
const OrderedTimeUnits = ['MINUTES', 'HOURS', 'DAYS', 'WEEKS'];
const defaults = {
  timeUnit: 'HOURS',
  numberOfTimeUnits: 1
};
export default class Reminder extends Record(defaults) {
  static from(json) {
    if (json) {
      return new Reminder({
        timeUnit: json.timeUnit || defaults.timeUnit,
        numberOfTimeUnits: json.numberOfTimeUnits || defaults.numberOfTimeUnits
      });
    }
    return new Reminder();
  }
  static compare(a, b) {
    if (OrderedTimeUnits.indexOf(a.get('timeUnit')) !== OrderedTimeUnits.indexOf(b.get('timeUnit'))) {
      return OrderedTimeUnits.indexOf(a.get('timeUnit')) - OrderedTimeUnits.indexOf(b.get('timeUnit'));
    } else {
      return a.get('numberOfTimeUnits') - b.get('numberOfTimeUnits');
    }
  }
}