'use es6';

import { useEffect } from 'react';
const getRenderFunc = useRecaptchaEnterprise => useRecaptchaEnterprise ? window.grecaptcha.enterprise.render : window.grecaptcha.render;
const loadScript = ({
  sitekey,
  onLoad,
  onSuccess,
  onExpired,
  onFailure,
  locale,
  useRecaptchaEnterprise,
  baseUrl
}) => {
  window.hsRecaptchaLoaded = () => {
    const widgetId = getRenderFunc(useRecaptchaEnterprise)('hsRecaptchaTarget', {
      sitekey,
      callback: onSuccess,
      'expired-callback': onExpired,
      'error-callback': onFailure,
      size: 'invisible',
      badge: 'inline'
    }, true);
    onLoad(widgetId);
  };
  const script = document.createElement('script');
  const path = useRecaptchaEnterprise ? 'enterprise' : 'api';
  script.async = true;
  script.defer = true;
  script.id = 'recaptcha';
  script.onerror = function (error) {
    throw error;
  };
  script.src = `${baseUrl}/${path}.js?&onload=hsRecaptchaLoaded&render=explicit&hl=${locale}`;
  script.type = 'text/javascript';
  document.body.appendChild(script);
};
export default (({
  onLoad,
  onSuccess,
  sitekey,
  locale,
  onExpired,
  onFailure,
  useRecaptchaEnterprise,
  baseUrl
}) => {
  useEffect(() => {
    loadScript({
      sitekey,
      onLoad,
      onSuccess,
      locale,
      onExpired,
      onFailure,
      useRecaptchaEnterprise,
      baseUrl
    });
  }, [sitekey, onLoad, onSuccess, locale, onExpired, onFailure, useRecaptchaEnterprise, baseUrl]);
});