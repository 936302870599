// @ts-expect-error no type declarations found
import { handleActions } from 'flux-actions';
import { Set as ImmutableSet } from 'immutable';
// @ts-expect-error no type declarations found
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
const initialState = ImmutableSet();
export default handleActions({
  [ActionTypes.ADD_GUEST_EMAIL](state, action) {
    return state.add(action.payload);
  },
  [ActionTypes.REMOVE_GUEST_EMAIL](state, action) {
    const email = action.payload;
    if (state.has(email)) {
      return state.delete(email);
    }
    return state;
  }
}, initialState);