import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
export const MAX_RETRIES = 5;
export function retryableHubHttpClient({
  httpVerb
}) {
  return function retryableHttpVerbClient({
    retryCount = 1,
    retryStatusCodes = [500, 0],
    url,
    options
  }) {
    return noAuthApiClient[httpVerb](url, options).catch(err => {
      const validRetryCount = Math.min(retryCount, MAX_RETRIES);
      const is5xxError = err.status >= 500;
      const shouldRetry = (is5xxError || retryStatusCodes.includes(err.status)) && validRetryCount > 0;
      if (shouldRetry) {
        console.log('retrying with retryableNoAuthHttpClient', httpVerb, validRetryCount);
        return retryableHttpVerbClient({
          retryCount: validRetryCount - 1,
          retryStatusCodes,
          url,
          options
        });
      } else {
        return Promise.reject(err);
      }
    });
  };
}
export function retryableGetClient(options) {
  return retryableHubHttpClient({
    httpVerb: 'get'
  })(options);
}
export function retryablePostClient(options) {
  return retryableHubHttpClient({
    httpVerb: 'post'
  })(options);
}
export function retryableDeleteClient(options) {
  return retryableHubHttpClient({
    httpVerb: 'delete'
  })(options);
}
export function retryablePatchClient(options) {
  return retryableHubHttpClient({
    httpVerb: 'patch'
  })(options);
}
export function retryablePutClient(options) {
  return retryableHubHttpClient({
    httpVerb: 'put'
  })(options);
}