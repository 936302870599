import { List, Record } from 'immutable';
const linkAvailabilityIntervalDefaults = {
  endMillisUtc: 0,
  startMillisUtc: 0
};
export class LinkAvailabilityIntervalRecord extends Record(linkAvailabilityIntervalDefaults) {
  static from(json) {
    return new LinkAvailabilityIntervalRecord({
      endMillisUtc: json.endMillisUtc,
      startMillisUtc: json.startMillisUtc
    });
  }
  equals(other) {
    return this.get('endMillisUtc') === other.get('endMillisUtc') && this.get('startMillisUtc') === other.get('startMillisUtc');
  }
}
const linkAvailabilityByDurationDefaults = {
  availabilities: List(),
  meetingDurationMillis: 0
};
export default class LinkAvailabilityByDurationRecord extends Record(linkAvailabilityByDurationDefaults) {
  static from(json) {
    return new LinkAvailabilityByDurationRecord({
      meetingDurationMillis: json.meetingDurationMillis,
      availabilities: List(json.availabilities.map(LinkAvailabilityIntervalRecord.from))
    });
  }

  /**
   * @deprecated
   * Use convertToJSON instead
   */
  toJS() {
    return super.toJS();
  }
  equals(other) {
    return JSON.stringify(this.convertToJSON()) === JSON.stringify(other.convertToJSON());
  }
  /**
   * @deprecated
   * Use convertToJSON instead
   */
  toJSON() {
    return super.toJSON();
  }
  convertToJSON() {
    const {
      meetingDurationMillis,
      availabilities
    } = this;
    return {
      meetingDurationMillis,
      availabilities: availabilities.toJS()
    };
  }
}