'use es6';

import { createSelector } from 'reselect';
import get from 'transmute/get';
import { STARTED, SUCCEEDED, FAILED, UNINITIALIZED } from 'MeetingsBase/constants/RequestStatusTypes';
const getRequestStatuses = state => state.requestStatus;
const getRequestErrors = state => state.networkErrors;
export function getRequestStatus(requestType) {
  return createSelector([getRequestStatuses], requestStatuses => requestStatuses.get(requestType) || UNINITIALIZED);
}
export function getRequestError(requestType) {
  return createSelector([getRequestErrors], requestErrors => requestErrors.get(requestType) || UNINITIALIZED);
}
const getBookInfo = get('bookInfo');
export const getMeetingsCreateStatus = getRequestStatus('MEETING_CREATE');
export const getMeetingsCancelStatus = getRequestStatus('MEETING_CANCEL');
export const getMeetingRescheduleStatus = getRequestStatus('MEETING_RESCHEDULE');
export const getMeetingsCancelError = getRequestError('meetingCancel');
export const getPublicAvailabilityInfoFetchStatus = getRequestStatus('PUBLIC_AVAILABILITY_INFO_FETCH');
export const getPublicAvailabilityOwnerFetchStatus = getRequestStatus('PUBLIC_AVAILABILITY_INFO_OWNER_FETCH');
const getMeetingsUserFetchStatus = getRequestStatus('MEETINGS_USER_FETCH');
export const getPublicGlobalBrandingFetchStatus = getRequestStatus('PUBLIC_GLOBAL_BRANDING_FETCH');
export const getCompanyNameFetchStatus = getRequestStatus('COMPANY_NAME_FETCH');
export const meetingCreationSuccess = createSelector([getMeetingsCreateStatus], meetingsCreateStatus => meetingsCreateStatus === SUCCEEDED);
export const meetingRescheduleSuccess = createSelector([getMeetingRescheduleStatus], meetingRescheduleStatus => meetingRescheduleStatus === SUCCEEDED);
export const meetingRescheduleFailed = createSelector([getMeetingRescheduleStatus], meetingRescheduleStatus => meetingRescheduleStatus === FAILED);
export const meetingCreationFailed = createSelector([getMeetingsCreateStatus, getMeetingRescheduleStatus], (meetingsCreateStatus, meetingRescheduleStatus) => meetingsCreateStatus === FAILED || meetingRescheduleStatus === FAILED);
export const availabilityFetchFailed = createSelector([getPublicAvailabilityInfoFetchStatus], publicAvailabilityInfoFetchStatus => publicAvailabilityInfoFetchStatus === FAILED);
export const availabilityFetchSuccess = createSelector([getPublicAvailabilityInfoFetchStatus], publicAvailabilityInfoFetchStatus => publicAvailabilityInfoFetchStatus === SUCCEEDED);
export const getIsFetchingPublicAvailability = createSelector([getPublicAvailabilityInfoFetchStatus], publicAvailabilityInfoFetchStatus => publicAvailabilityInfoFetchStatus === UNINITIALIZED || publicAvailabilityInfoFetchStatus === STARTED);
export const getIsFetchingOwnerPublicAvailability = createSelector([getPublicAvailabilityOwnerFetchStatus], publicAvailabilityOwnerFetchStatus => {
  return publicAvailabilityOwnerFetchStatus === UNINITIALIZED || publicAvailabilityOwnerFetchStatus === STARTED;
});
export const meetingsUserFetchStarted = createSelector([getMeetingsUserFetchStatus], meetingsUserFetchStatus => meetingsUserFetchStatus === STARTED);
export const meetingsUserFetchFailed = createSelector([getMeetingsUserFetchStatus], meetingsUserFetchStatus => meetingsUserFetchStatus === FAILED);
export const meetingsUserFetchSuccess = createSelector([getMeetingsUserFetchStatus], meetingsUserFetchStatus => meetingsUserFetchStatus === SUCCEEDED);
export const getIsFetchingCompanyName = createSelector([getCompanyNameFetchStatus], companyNameFetchStatus => companyNameFetchStatus === UNINITIALIZED || companyNameFetchStatus === STARTED);
export const bookingPageLoading = getIsFetchingPublicAvailability;
export const shouldRenderBookingPage = createSelector([bookingPageLoading, getMeetingsCreateStatus, getMeetingRescheduleStatus], (isBookingPageLoading, meetingsCreateStatus, meetingRescheduleStatus) => {
  const meetingNotCreatedYet = meetingsCreateStatus === UNINITIALIZED || meetingsCreateStatus === STARTED;
  const meetingNotRescheduledYet = meetingRescheduleStatus === UNINITIALIZED || meetingRescheduleStatus === STARTED;
  return !isBookingPageLoading && meetingNotCreatedYet && meetingNotRescheduledYet;
});
export const isSubmittingMeeting = createSelector([getMeetingsCreateStatus, getMeetingRescheduleStatus], (meetingsCreateStatus, meetingRescheduleStatus) => meetingsCreateStatus === STARTED || meetingRescheduleStatus === STARTED);
export const fetchBookInfoFailed = createSelector([getPublicAvailabilityInfoFetchStatus, getBookInfo], (publicAvailabilityInfoFetchStatus, {
  availableTimePeriods
}) => publicAvailabilityInfoFetchStatus === FAILED || !availableTimePeriods);