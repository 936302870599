import anonymousStorage from 'usage-tracker-core/containers/anonymousStorage';
import tempStorage from 'usage-tracker-core/containers/tempStorage';
import { getHamplitudeProperties } from 'usage-tracker-core/metaProperties';
import { hamplitudeKey } from 'usage-tracker-core/storageKeys';

// This attempts to get an UUID from the URL, if it doesn't exist it will simply return undefined
export const getUuidFromUrl = location => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get('uuid') || undefined;
};
export const resetAmplitudeStorageIfNeeded = deviceId => {
  try {
    // This method returns null if there are no hamplitudeProperties on the storage
    const hamplitudeProperties = getHamplitudeProperties(tempStorage.getItem);

    // If we have the same deviceId stored on the localStorage we should reset that
    // since we shouldn't identify the same user with different devices
    if (hamplitudeProperties && hamplitudeProperties.device_id === deviceId) {
      tempStorage.removeItem(hamplitudeKey);
    }
  } catch (e) {
    // noop
  }

  // When switching from anonymous to non-anonymous we want to clear the
  // anonymous tracker sessionStorage
  anonymousStorage.removeItem(hamplitudeKey);
};