"use es6";

import { invariant, newInvariantError, InvariantError } from "apollo-stack-hubspot/@apollo/client/utilities/globals/invariantWrappers";
export { maybe } from "apollo-stack-hubspot/@apollo/client/utilities/globals/maybe";
export { default as global } from "apollo-stack-hubspot/@apollo/client/utilities/globals/global";
export { invariant, newInvariantError, InvariantError };
/**
 * @deprecated we do not use this internally anymore,
 * it is just exported for backwards compatibility
 */
// this file is extempt from automatic `__DEV__` replacement
// so we have to write it out here
// @ts-ignore
export var DEV = process.env.NODE_ENV !== "production";
export { DEV as __DEV__ };