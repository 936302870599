import Raven from 'raven-js';
import { OLAF, OBSIDIAN, CALYPSO } from 'HubStyleTokens/colors';
const SHORTHAND_HEX_PATTERN = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
const REGULAR_HEX_PATTERN = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
export function hexToRGB(hex) {
  let rgbObject;
  try {
    const regularHex = hex.replace(SHORTHAND_HEX_PATTERN, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = REGULAR_HEX_PATTERN.exec(regularHex);
    rgbObject = {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      r: parseInt(result[1], 16),
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      g: parseInt(result[2], 16),
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      b: parseInt(result[3], 16)
    };
  } catch (error) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    Raven.captureException(error, {
      extra: {
        hex
      }
    });
    rgbObject = {
      r: 0,
      g: 0,
      b: 0
    };
  }
  return rgbObject;
}
function getL(hex) {
  const rgb = hexToRGB(hex);
  const r = rgb.r / 255.0;
  const rL = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  const g = rgb.g / 255.0;
  const gL = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  const b = rgb.b / 255.0;
  const bL = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
  return 0.2126 * rL + 0.7152 * gL + 0.0722 * bL;
}
export function canReadText(backgroundHex, textColor) {
  const backL = getL(backgroundHex);
  const textL = getL(textColor);
  const contrastRatio = (textL + 0.05) / (backL + 0.05);
  return contrastRatio > 2.0;
}
export function getLinkUse(backgroundHex) {
  const backL = getL(backgroundHex);
  const textL = getL(CALYPSO); // default link color

  const contrastRatio = (textL + 0.05) / (backL + 0.05);
  return contrastRatio > 2.0 ? 'default' : 'on-dark';
}
export function getTextColor(primaryColor) {
  return canReadText(primaryColor, OLAF) ? OLAF : OBSIDIAN;
}

/*
 * Util function to determine the color of text to display against a white
 * background. If the primaryColor is dark enough to render against a white
 * background, use it. Otherwise, use the element's default coloring
 */
export function getPrimaryColorOrDefault(primaryColor) {
  return canReadText(primaryColor, OLAF) ? primaryColor : null;
}