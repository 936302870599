'use es6';

import Raven from 'raven-js';
import get from 'transmute/get';
import { createSelector } from 'reselect';
import { INVALID_DOMAIN_FOR_LINK } from 'MeetingsLib/constants/errors';
const getNetworkErrors = get('networkErrors');
const getResponseJSON = response => {
  if (!response) {
    return null;
  }
  if (response.responseJSON) {
    return response.responseJSON;
  }
  if (response.responseText) {
    let parsed = null;
    try {
      parsed = JSON.parse(response.responseText);
    } catch (e) {
      Raven.captureException(e);
      return parsed;
    }
    return parsed;
  }
  return null;
};
export const getBookInfoError = createSelector([getNetworkErrors], networkErrors => {
  const bookInfoError = get('publicAvailabilityInfoFetch', networkErrors);
  const responseJSON = getResponseJSON(bookInfoError);
  return get('errorType', responseJSON) || get('message', responseJSON) || null;
});
export const isInvalidDomainError = createSelector([getBookInfoError], bookInfoError => bookInfoError === INVALID_DOMAIN_FOR_LINK);
export const getRescheduleError = createSelector([getNetworkErrors], networkErrors => {
  const meetingRescheuduleError = get('meetingReschedule', networkErrors);
  const responseJSON = getResponseJSON(meetingRescheuduleError);
  return get('errorType', responseJSON) || get('message', responseJSON) || null;
});