import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["legitimateInterestPrivacyPolicyText", "implicitProcessingConsentText"];
import { Record, List } from 'immutable';
import LegalConsentCheckbox from 'MeetingsBase/models/LegalConsentCheckbox';
import { IMPLICIT_PROCESSING_CONSENT_TYPE, LAWFUL_BASIS_PQL } from 'MeetingsBase/constants/legalConsent';
export const parseHtmlString = htmlString => new DOMParser().parseFromString(htmlString, 'text/html');
const isEmptyHTML = text => {
  return parseHtmlString(text).body.innerText.trim() === '';
};
const isEmptyParagraph = text => !text || text === '<p></p>' || isEmptyHTML(text);
function isLegalConsentObject(json) {
  return !!json.processingConsentText;
}
const defaults = {
  communicationConsentCheckboxes: List([new LegalConsentCheckbox()]),
  communicationConsentText: '',
  implicitProcessingConsentText: '',
  isLegitimateInterest: false,
  legitimateInterestLegalBasis: LAWFUL_BASIS_PQL,
  legitimateInterestPrivacyPolicyText: '',
  legitimateInterestSubscriptionTypes: List(),
  privacyPolicyText: '',
  processingConsentText: '',
  processingConsentType: ''
};
export default class LegalConsentOptions extends Record(defaults) {
  static from(json) {
    if (json) {
      return new LegalConsentOptions({
        isLegitimateInterest: json.isLegitimateInterest || false,
        legitimateInterestPrivacyPolicyText: this.getLegitimateInterestText(json),
        legitimateInterestSubscriptionTypes: this.getLegitimateInterestSubscriptions(json),
        legitimateInterestLegalBasis: json.legitimateInterestLegalBasis || LAWFUL_BASIS_PQL,
        communicationConsentText: json.communicationConsentText,
        communicationConsentCheckboxes: this.getCheckboxConfig(json),
        privacyPolicyText: json.privacyPolicyText,
        implicitProcessingConsentText: this.getProcessingText(json),
        processingConsentText: json.processingConsentText,
        processingConsentType: json.processingConsentType
      });
    }
    return new LegalConsentOptions();
  }
  static getLegitimateInterestText(json) {
    if (json.isLegitimateInterest) {
      return json.privacyPolicyText;
    }
    return json.legitimateInterestPrivacyPolicyText || '';
  }
  static getCheckboxConfig(json) {
    const checkboxConfig = json.communicationConsentCheckboxes;
    if (checkboxConfig && !!checkboxConfig.map && json.communicationConsentCheckboxes) {
      return List(json.communicationConsentCheckboxes.map(checkbox => new LegalConsentCheckbox(checkbox)));
    }
    return List([new LegalConsentCheckbox()]);
  }
  static getLegitimateInterestSubscriptions(json) {
    const subscriptionsConfig = json.legitimateInterestSubscriptionTypes;
    if (subscriptionsConfig && !!subscriptionsConfig.map) {
      return List(subscriptionsConfig);
    }
    return List();
  }
  static getProcessingText(json) {
    if (isLegalConsentObject(json)) {
      return json.processingConsentText;
    }
    return json.implicitProcessingConsentText || '';
  }
  hasSubscription(id) {
    return Boolean(this.communicationConsentCheckboxes.find(subscription => subscription.communicationTypeId === id));
  }
  hasLegitimateInterestSubscription(id) {
    return this.legitimateInterestSubscriptionTypes.includes(id);
  }
  hasEmptySubscription() {
    return Boolean(this.communicationConsentCheckboxes.find(subscription => !subscription.communicationTypeId));
  }
  getNumberOfSubscriptions() {
    return this.communicationConsentCheckboxes.size;
  }
  getSubscriptions() {
    return this.communicationConsentCheckboxes;
  }
  getCheckboxLabels() {
    return this.communicationConsentCheckboxes.map(checkbox => checkbox.label);
  }
  convertToJSON() {
    const json = this.toJS();
    const {
        legitimateInterestPrivacyPolicyText,
        implicitProcessingConsentText
      } = json,
      restJson = _objectWithoutPropertiesLoose(json, _excluded);
    const result = Object.assign({}, restJson, {
      processingConsentText: implicitProcessingConsentText || '',
      processingConsentType: IMPLICIT_PROCESSING_CONSENT_TYPE
    });

    /*
     * Forms saves legitimateInterestPrivacyPolicyText into privacyPolicyText
     * when isLegitimateInterest is true.
     */
    if (json.isLegitimateInterest) {
      result.privacyPolicyText = legitimateInterestPrivacyPolicyText;
    }
    return result;
  }
  createdFromNull() {
    return this.equals(LegalConsentOptions.from(null));
  }
  lacksSubscriptionId() {
    return this.communicationConsentCheckboxes && this.communicationConsentCheckboxes.first() && !this.communicationConsentCheckboxes.first().communicationTypeId || this.legitimateInterestSubscriptionTypes.isEmpty();
  }
  lacksLegitimateInterestText() {
    return !this.legitimateInterestPrivacyPolicyText;
  }
  allCheckboxesHaveLabels() {
    return this.communicationConsentCheckboxes.every(checkbox => !isEmptyParagraph(checkbox.label));
  }
  filterEmptyConsentSubscriptions() {
    return this.update('communicationConsentCheckboxes', checkboxes => checkboxes.filter(checkbox => Boolean(checkbox.communicationTypeId)));
  }
  filterEmptyLegitimateInterestSubscriptions() {
    return this.update('legitimateInterestSubscriptionTypes', subscriptions => subscriptions.filter(subscription => Boolean(subscription)));
  }
}