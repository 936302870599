import { Record, Map as ImmutableMap } from 'immutable';
import LinkAvailabilityByDurationRecord from './LinkAvailabilityByDuration';
const defaults = {
  hasMore: false,
  hasMoreByMonthOffset: ImmutableMap(),
  isProcessing: false,
  linkAvailabilityByDuration: ImmutableMap()
};
export default class LinkAvailability extends Record(defaults) {
  static from(json) {
    return new LinkAvailability({
      linkAvailabilityByDuration: LinkAvailability.buildLinkAvailabilityByDurationFromJson(json),
      hasMore: json.hasMore,
      hasMoreByMonthOffset: ImmutableMap({
        0: json.hasMore
      })
    });
  }
  convertToJSON() {
    const {
      hasMore,
      linkAvailabilityByDuration,
      hasMoreByMonthOffset
    } = this;
    return {
      hasMore,
      hasMoreByMonthOffset: hasMoreByMonthOffset && hasMoreByMonthOffset.toJS() || {},
      linkAvailabilityByDuration: linkAvailabilityByDuration.toJS(),
      isProcessing: false
    };
  }
  static buildLinkAvailabilityByDurationFromJson(json) {
    const keys = Object.keys(json.linkAvailabilityByDuration);
    const linkAvailabilityByDurationMap = keys.reduce((map, key) => {
      const linkAvailabilityByDuration = json.linkAvailabilityByDuration[key];
      return map.set(key, LinkAvailabilityByDurationRecord.from(linkAvailabilityByDuration));
    }, ImmutableMap());
    return linkAvailabilityByDurationMap;
  }
  equals(other) {
    return JSON.stringify(this.convertToJSON()) === JSON.stringify(other.convertToJSON());
  }
  hasZeroAvailability() {
    const {
      hasMore,
      isProcessing,
      linkAvailabilityByDuration
    } = this;
    return !hasMore && !isProcessing && !linkAvailabilityByDuration.some(duration => duration.get('availabilities').size > 0);
  }

  /**
   * Iterates over incoming payload availabilities per duration
   * and adds non-existing availabilities to this instance's linkAvailabilityByDuration
   * @param incomingLinkAvailability
   * @returns LinkAvailability
   */
  updateWithIncomingInstance({
    incomingLinkAvailability,
    currentMonthOffset
  }) {
    const incomingLinkAvailabilityByDuration = incomingLinkAvailability.get('linkAvailabilityByDuration');
    let existingLinkAvailabilityByDuration = this.get('linkAvailabilityByDuration');

    // convert current to Map<duration, Set<LinkAvailabilityIntervalRecord>>
    let currentDurationbyAvailabilityIntervalsMap = existingLinkAvailabilityByDuration.keySeq().reduce((map, current) => {
      const durationKey = current;
      const availabilityIntervalList = existingLinkAvailabilityByDuration.get(current).get('availabilities');
      const availabilityIntervalSet = availabilityIntervalList.toOrderedSet();
      // there are no duplicate duration keys
      map = map.set(durationKey, availabilityIntervalSet);
      return map;
    }, ImmutableMap());
    incomingLinkAvailabilityByDuration.keySeq().forEach(durationKey => {
      const availabilityIntervalList = incomingLinkAvailabilityByDuration.get(durationKey).get('availabilities');
      if (currentDurationbyAvailabilityIntervalsMap.has(durationKey)) {
        let currentAvailabilitiesForDurationSet = currentDurationbyAvailabilityIntervalsMap.get(durationKey);
        // adds without duplicates since the Set's underlying model implements .equals
        availabilityIntervalList.forEach(incomingInterval => {
          currentAvailabilitiesForDurationSet = currentAvailabilitiesForDurationSet.add(incomingInterval);
        });
        currentDurationbyAvailabilityIntervalsMap = currentDurationbyAvailabilityIntervalsMap.set(durationKey, currentAvailabilitiesForDurationSet);
      }
    });
    existingLinkAvailabilityByDuration.keySeq().forEach(durationKey => {
      const updatedAvailabilityList = currentDurationbyAvailabilityIntervalsMap.get(durationKey).toList();
      const updatedLinkAvailabilityByDuration = existingLinkAvailabilityByDuration.get(durationKey).set('availabilities', updatedAvailabilityList);
      existingLinkAvailabilityByDuration = existingLinkAvailabilityByDuration.set(durationKey, updatedLinkAvailabilityByDuration);
    });
    let updatedHasMoreAtMonthOffset = this.hasMoreByMonthOffset;
    updatedHasMoreAtMonthOffset = updatedHasMoreAtMonthOffset.set(currentMonthOffset, incomingLinkAvailability.get('hasMore'));
    return new LinkAvailability().set('linkAvailabilityByDuration', existingLinkAvailabilityByDuration).set('hasMore', incomingLinkAvailability.get('hasMore')).set('isProcessing', false).set('hasMoreByMonthOffset', updatedHasMoreAtMonthOffset);
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}