// @ts-expect-error no type declarations found
import { handleActions } from 'flux-actions';
// @ts-expect-error no type declarations found
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
import TroubleshootTimes from 'MeetingsBase/models/TroubleshootTimes';
const initialState = {
  active: false,
  data: new TroubleshootTimes()
};
export default handleActions({
  [ActionTypes.TOGGLE_TROUBLESHOOT_MODE](state, action) {
    return Object.assign({}, state, {
      active: action.active
    });
  },
  [ActionTypes.RECEIVE_TROUBLESHOOT_TIMES](state, action) {
    return Object.assign({}, state, {
      data: TroubleshootTimes.from(action.data)
    });
  },
  [ActionTypes.UPDATE_TROUBLESHOOT_TIMES](state, action) {
    const updated = state.data.updateWithIncomingInstance(action.data);
    return Object.assign({}, state, {
      data: updated
    });
  }
}, initialState);