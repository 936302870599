import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["customFormFields"];
// @ts-expect-error untyped import
import { handleActions } from 'flux-actions';
import { Map as ImmutableMap } from 'immutable';
import FormState from 'MeetingsLib/models/FormState';
// @ts-expect-error untyped import
import * as ActionTypes from '../actions/ActionTypes';
const initialState = null;
export default handleActions({
  [ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED](state, action) {
    const {
      bookInfo: {
        formPrefills
      },
      customFormFields: configuredCustomFormFields
    } = action.payload;
    state = state || new FormState();
    // merge fields from query params with fields saved on link
    // don't filter out fields that are not saved on link
    // support "hidden field" submissions SMMC-1144
    let _state$toObject = state.toObject(),
      {
        customFormFields
      } = _state$toObject,
      defaultFormFields = _objectWithoutPropertiesLoose(_state$toObject, _excluded);
    // @ts-expect-error fix type
    defaultFormFields = ImmutableMap(defaultFormFields).map((field, key) => field || formPrefills.get(key));
    customFormFields = configuredCustomFormFields
    // @ts-expect-error fix type
    .map((field, key) => formPrefills.getIn(['customFormFields', key], field)).merge(state.customFormFields);
    return state.merge(defaultFormFields).set('customFormFields', customFormFields).set('originalFormState', state.delete('originalFormState'));
  },
  [ActionTypes.PUBLIC_AVAILABILITY_INFO_OWNER_FETCH_SUCCEEDED](state, action) {
    const {
      bookInfo: {
        formPrefills
      },
      customFormFields: configuredCustomFormFields
    } = action.payload;
    // @ts-expect-error fix type
    const {
      customFormFields
    } = state.originalFormState.toObject();
    // @ts-expect-error fix type
    return state.email !== formPrefills.email ? FormState.from({
      // @ts-expect-error fix type
      email: state.email,
      customFormFields: configuredCustomFormFields.merge(customFormFields)
    }) : state;
  }
}, initialState);