import { List, Record } from 'immutable';
const conflictingEventsDefaults = {
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  relatedEventStartTimeMillis: 0
};
export class ConflictingEventRecord extends Record(conflictingEventsDefaults) {
  static from(json) {
    return new ConflictingEventRecord(Object.assign({}, json));
  }

  /**
   * @deprecated use toJSON instead to generate the correct types
   * @returns
   */
  toJS() {
    return super.toJS();
  }
}
const intervalDefaults = {
  startMillisUtc: 0,
  endMillisUtc: 0,
  available: false,
  reason: 'PAST',
  conflictingEvents: List()
};
export default class TroubleshootTimeIntervalRecord extends Record(intervalDefaults) {
  static from(json) {
    return new TroubleshootTimeIntervalRecord(Object.assign({}, json, {
      conflictingEvents: List(json.conflictingEvents.map(ConflictingEventRecord.from))
    }));
  }
  equals(other) {
    return this.get('startMillisUtc') === other.get('startMillisUtc') && this.get('endMillisUtc') === other.get('endMillisUtc');
  }
  /**
   * @deprecated use convertToJSON instead
   * @returns
   */
  toJS() {
    return super.toJS();
  }

  /**
   * @deprecated use convertToJSON instead
   * @returns
   */
  toJSON() {
    return super.toJSON();
  }
  convertToJSON() {
    return {
      endMillisUtc: this.get('endMillisUtc'),
      startMillisUtc: this.get('startMillisUtc'),
      available: this.get('available'),
      reason: this.get('reason'),
      conflictingEvents: this.get('conflictingEvents').toArray().map(conflictingEventRecord => conflictingEventRecord.toJSON())
    };
  }
}