import Url from 'urlinator/Url';
function sanitizePathname(pathname) {
  const lastIndex = pathname.length - 1;
  if (pathname.charAt(lastIndex) === '/') {
    return pathname.substring(0, lastIndex);
  }
  return pathname;
}
export function getSlug(href = '') {
  /**
   * If the pathname contains "/meetings/" (old domain pattern, custom domain pattern)
   * then we should split pathname on 'meetings' to grab the slug
   * Otherwise, we are on the domain pattern meetings.hubspot.com
   * and the slug is just the pathname with leading '/' removed
   * @param {string} href url to parse to get the unique identifier
   * @returns {string} The visible unique identifier of the meeting link
   */
  const url = new Url(href);
  if (url.pathname.indexOf('/meetings/') > -1) {
    return sanitizePathname(url.pathname).split('/meetings/')[1];
  }
  return sanitizePathname(url.pathname).slice(1);
}