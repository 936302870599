// @ts-expect-error untyped import
import { combineActions, handleActions } from 'flux-actions';
// @ts-expect-error untyped import
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
const initialState = -1;
export default handleActions({
  [combineActions(ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED, ActionTypes.MEETINGS_USER_FETCH_SUCCEEDED)](state, action) {
    const {
      meetingsUser
    } = action.payload;
    if (!meetingsUser) {
      return state;
    }
    return action.payload.meetingsUser.portalId;
  }
}, initialState);