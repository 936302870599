// @ts-expect-error untyped import
import { handleActions } from 'flux-actions';
import getTimezoneGroups from 'timezone-utils/utils/getTimezoneGroups';
import { INVALID_TZ_GUESS } from 'MeetingsLib/constants/timezone';
// @ts-expect-error untyped import
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
const initialState = 'Europe/Belfast';
export default handleActions({
  [ActionTypes.SET_TIMEZONE](state, action) {
    return action.timezone;
  },
  [ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED](state, action) {
    const {
      bookInfo: {
        customParams: {
          timezone
        }
      }
    } = action.payload;
    if (state === INVALID_TZ_GUESS) {
      return getTimezoneGroups().resolve(timezone);
    }
    return state;
  }
}, initialState);