// @ts-expect-error untyped import
import { handleActions } from 'flux-actions';
import BookingLifecycle from 'MeetingsLib/models/BookingLifecycle';
import Event from 'MeetingsLib/models/Event';
// @ts-expect-error untyped import
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
const eventInitialState = new Event();
const event = handleActions({
  [ActionTypes.SET_EVENT_DATE](state, action) {
    return state.set('dateString', action.date).set('dateTime', null);
  },
  [ActionTypes.SET_EVENT_DATE_TIME](state, action) {
    return state.set('dateTime', action.dateTime);
  },
  [ActionTypes.BOOKING_TIME_CONFLICT](state) {
    return state.set('dateTime', null).set('bookingTimeConflictStatus', true);
  },
  [ActionTypes.RESET_BOOKING_TIME_CONFLICT_STATUS](state) {
    return state.set('bookingTimeConflictStatus', false);
  },
  [ActionTypes.SET_EVENT_DURATION](state, action) {
    return state.set('duration', action.duration).set('dateTime', null);
  },
  [ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED](state, action) {
    const durations = action.payload.bookInfo.customParams.durations;
    const stateWithDuration = state.set('duration', durations.first());
    const formFields = action.payload.bookInfo.customParams.formFields;
    return stateWithDuration.set('formFields', formFields);
  }
}, eventInitialState);
const postResponseInitialState = null;
const postResponse = handleActions({
  [ActionTypes.MEETING_CREATE_SUCCEEDED](state, action) {
    return action.payload;
  },
  [ActionTypes.MEETING_CREATE_RESET]() {
    return postResponseInitialState;
  },
  [ActionTypes.MEETING_RESCHEDULE_SUCCEEDED](state, action) {
    return action.payload;
  }
}, postResponseInitialState);
export default ((state = new BookingLifecycle(), action) => {
  return state.set('event', event(state.event, action)).set('postResponse', postResponse(state.postResponse, action));
});