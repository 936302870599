import { registerQuery, useQuery } from 'data-fetching-client';
import { validateEmail } from '../api/meetingsEmailAPI';
export const VALIDATE_EMAIL = registerQuery({
  fieldName: 'isValid',
  args: ['email'],
  fetcher: ({
    email
  }) => validateEmail(email)
});
export const useEmailValidation = ({
  client,
  shouldSkip,
  email
}) => {
  const emailValidationQueryOptions = {
    skip: shouldSkip || !email,
    fetchPolicy: 'cache-first',
    variables: {
      email
    }
  };
  const {
    data,
    error,
    refetch,
    loading
  } = useQuery(VALIDATE_EMAIL, client ? Object.assign({
    client
  }, emailValidationQueryOptions) : emailValidationQueryOptions);
  const isValid = data && data.isValid || false;
  return {
    isValid,
    reValidateEmail: refetch,
    error,
    loading
  };
};