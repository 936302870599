import { Record } from 'immutable';
const defaults = {
  content: '',
  type: 'info'
};
export default class UserMessage extends Record(defaults, 'UserMessage') {
  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}