'use es6';

import I18n from 'I18n';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import get from 'transmute/get';
import { is, List } from 'immutable';
import { getBookableDays, getListOfDays, getConfiguredAvailableTimeRanges as getConfiguredAvailableTimeRangesUtil } from 'MeetingsBase/utils/utils';
import { getCurrentTime } from 'MeetingsLib/selectors/RESTParamSelectors';
import { getFlexibleIntervalStartTimes } from 'MeetingsBase/utils/TimeRangeListUtils';
import { getCustomParams as getCustomParamsSelector, getUserTimezone, getEvent, getMeetingLength, getOffline, getStartTimeIntervalMilliseconds, getIsUngatedForAvailabilityDaysLimit } from 'MeetingsLib/selectors/BookInfoSelectors';
import { getEmbedded } from 'MeetingsLib/selectors/RESTParamSelectors';
import { ONE_DAY } from 'MeetingsBase/constants/dates';
import AvailabilityMilliseconds from 'MeetingsBase/models/AvailabilityMilliseconds';
import getIn from 'transmute/getIn';
import RollingDateRange from 'MeetingsBase/models/RollingDateRange';
import { isAllowedToTroubleshootMeetingsLink } from 'MeetingsLib/utils/utils';
const createSelector = createSelectorCreator(defaultMemoize, is);
const getTroubleshooting = get('troubleshooting');
export const shouldAllowTroubleshootMode = get('allowTroubleshootMode');
export const userLoggedInToValidPortal = state => {
  const portalIdFromUserProfile = get('portalId', state);
  const portalIdFromAuth = getIn(['portalAuth', 'portal', 'portal_id'], state);
  return portalIdFromUserProfile && portalIdFromAuth && portalIdFromUserProfile === portalIdFromAuth;
};
export const canTroubleshootSelector = createSelector([getEmbedded, getOffline, shouldAllowTroubleshootMode, userLoggedInToValidPortal], (embedded, offline, allowTroubleshootMode, loggedIn) => {
  const isValidTroubleshootingDomain = isAllowedToTroubleshootMeetingsLink(window.location.href);
  return !embedded && !offline && allowTroubleshootMode && loggedIn && isValidTroubleshootingDomain || false;
});
export const isTroubleshootingSelector = createSelector([getTroubleshooting, canTroubleshootSelector], ({
  active: troubleshootingActive
}, canTroubleshoot) => {
  return troubleshootingActive && canTroubleshoot;
});
export const getTroubleshootTimes = createSelector([getTroubleshooting], ({
  data: troubleshootTimes
}) => troubleshootTimes);
export const getConfiguredAvailableTimeRanges = createSelector([isTroubleshootingSelector, getCustomParamsSelector, getCurrentTime, getIsUngatedForAvailabilityDaysLimit], (troubleshooting, customParams, currentTime, isUngatedForAvailabilityDaysLimit) => {
  if (!troubleshooting) {
    return List();
  }
  const rollingDateRange = isUngatedForAvailabilityDaysLimit ? RollingDateRange.from(customParams.rollingDateRange) : null;
  return getConfiguredAvailableTimeRangesUtil(AvailabilityMilliseconds.fromAvailability(customParams.availability), customParams.weeksToAdvertise, customParams.customAvailabilityStartDate, customParams.customAvailabilityEndDate, customParams.timezone, currentTime, rollingDateRange);
});
export const getConfiguredPossibleStartTimes = createSelector([isTroubleshootingSelector, getStartTimeIntervalMilliseconds, getConfiguredAvailableTimeRanges, getEvent], (isTroubleShooting, startTimeIntervalMilliseconds, configuredAvailableTimeRanges, event) => {
  if (!isTroubleShooting) {
    return List();
  }
  return getFlexibleIntervalStartTimes(configuredAvailableTimeRanges, event.duration, startTimeIntervalMilliseconds);
});
export const getTroubleshootTimesByDuration = createSelector([getTroubleshooting], troubleshooting => getIn(['data', 'troubleshootTimesByDuration'], troubleshooting) || {});
export const getTroubleshootTimesForSelectedDuration = createSelector([getTroubleshootTimesByDuration, getMeetingLength], (timesByDuration, duration) => getIn([`${duration}`, 'troubleshootTimes'], timesByDuration) || List());
export const getTroubleshootTimesForSelectedDurationByDay = createSelector([getTroubleshootTimesForSelectedDuration, getUserTimezone], (troubleshootTimes, userTimezone) => {
  return troubleshootTimes ? List(troubleshootTimes).groupBy(troubleshootTime => I18n.moment(troubleshootTime.startMillisUtc).tz(userTimezone).format('YYYY-MM-DD')) : List();
});

// Date selectors

export const getTroubleshootStartTimesForSelectedDate = createSelector([getTroubleshootTimesForSelectedDurationByDay, getEvent], (troubleshootTimesForSelectedDurationByDay, event) => troubleshootTimesForSelectedDurationByDay.get(event.dateString) || List());
export const getConfiguredBookableDates = createSelector([isTroubleshootingSelector, getCustomParamsSelector, getCurrentTime, getIsUngatedForAvailabilityDaysLimit], (troubleshooting, customParams, currentTime, isUngatedForAvailabilityDaysLimit) => {
  if (!troubleshooting) {
    return List();
  }
  const {
    weeksToAdvertise,
    customAvailabilityStartDate,
    customAvailabilityEndDate,
    rollingDateRange
  } = customParams;
  const parsedRollingDate = isUngatedForAvailabilityDaysLimit ? RollingDateRange.from(rollingDateRange) : null;
  return getBookableDays(weeksToAdvertise, customAvailabilityStartDate, customAvailabilityEndDate, currentTime, parsedRollingDate).map(startDayTimestamp => I18n.moment.utc(startDayTimestamp).format('YYYY-MM-DD'));
});
export const getConfiguredAvailabilityByDate = createSelector([getConfiguredPossibleStartTimes, getUserTimezone], (configuredPossibleStartTimes, userTimezone) => configuredPossibleStartTimes.groupBy(startTime => I18n.moment(startTime).tz(userTimezone).format('YYYY-MM-DD')));
export const getMinimumNoticeTimeBusyDays = createSelector([getCustomParamsSelector, getCurrentTime], (customParams, currentTime) => {
  const {
    minimumNoticeTime
  } = customParams;
  if (minimumNoticeTime < ONE_DAY) {
    return List();
  }
  const numberOfDays = Math.floor(minimumNoticeTime / ONE_DAY);
  const listOfDays = getListOfDays(I18n.moment(currentTime), numberOfDays);
  return listOfDays.map(timestamp => I18n.moment(timestamp).format('YYYY-MM-DD'));
});