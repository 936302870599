// @ts-expect-error untyped import
import { combineActions, handleActions } from 'flux-actions';
// @ts-expect-error untyped import
import * as ActionTypes from '../actions/ActionTypes';
const initialState = null;
export default handleActions({
  [ActionTypes.SET_CONSENT_RESPONSES](state, action) {
    return action.payload;
  },
  [combineActions(ActionTypes.RESET_CONSENT_RESPONSES, ActionTypes.BOOKING_TIME_CONFLICT)]() {
    return initialState;
  }
}, initialState);