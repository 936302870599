/* hs-eslint ignored failing-rules */
/* eslint-disable no-useless-escape */

'use es6';

import I18n from 'I18n';
import getDescriptionHashKey from './internal/getDescriptionHashKey';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
const re = /[\()\/\.\:\?\,\s\-\–\'&]/gi;
const nameSpaceKey = 'recordProperties';
const descriptionsNameSpaceKey = `${nameSpaceKey}.propertyDescriptions`;
const silentI18nText = key => isValidI18nKey(key) ? I18n.text(key) : undefined;
const tryLookup = (key, aString) => silentI18nText(key) || aString;
const isInvalidString = maybeString => typeof maybeString !== 'string' || maybeString.length === 0;
const convertToKey = aString => isInvalidString(aString) ? '' : aString.replace(re, '_').toLowerCase();
const makeNameSpacedKey = aString => `${nameSpaceKey}.${convertToKey(aString)}`;
const makeDescriptionKey = (label, description) => {
  return `${descriptionsNameSpaceKey}.${getDescriptionHashKey(description)}`;
};
const tryYourTranslation = (aString, optionalKeyPrefix) => {
  let keyString = convertToKey(aString);
  if (!isInvalidString(optionalKeyPrefix)) {
    keyString = `${optionalKeyPrefix}.${keyString}`;
  }
  return tryLookup(keyString, aString);
};
const propertyLabelTranslator = (label, nameSpaceLookupKey = nameSpaceKey) => {
  if (label && label === label.toUpperCase()) {
    return label;
  }
  return tryYourTranslation(label, nameSpaceLookupKey);
};
const propertyLabelTranslatorWithIsHubSpotDefined = ({
  label,
  nameSpaceLookupKey,
  isHubSpotDefined
}) => {
  if (!isHubSpotDefined) {
    return label;
  }
  return propertyLabelTranslator(label, nameSpaceLookupKey || nameSpaceKey);
};
const propertyDescriptionTranslator = (label, description) => {
  if (isInvalidString(description) || typeof I18n.lookup(descriptionsNameSpaceKey) !== 'object') {
    return description;
  }
  const descriptionKey = makeDescriptionKey(label, description);
  return tryLookup(descriptionKey, description);
};
const dealHubspotStages = {
  //deal stages - BE file here: https://git.hubteam.com/HubSpot/Deals/blob/master/DealsBase/src/main/java/com/hubspot/deals/base/DealsPipeline.java
  appointmentscheduled: 'appointment scheduled',
  qualifiedtobuy: 'qualified to buy',
  presentationscheduled: 'presentation scheduled',
  decisionmakerboughtin: 'decision maker bought-in',
  contractsent: 'contract sent',
  closedwon: 'closed won',
  closedlost: 'closed lost'
};
const lifecycleStages = {
  //BE file here - https://git.hubteam.com/HubSpot/ObjectPipelines/blob/master/PipelinesCore/src/main/java/com/hubspot/objectpipelines/models/DefaultLifecycleStage.java#L17
  subscriber: 'subscriber',
  lead: 'lead',
  marketingqualifiedlead: 'marketingqualifiedlead',
  salesqualifiedlead: 'salesqualifiedlead',
  opportunity: 'opportunity',
  customer: 'customer',
  evangelist: 'evangelist',
  other: 'other'
};

//pipeline stages - BE file here: https://git.hubteam.com/HubSpot/ObjectPipelines/blob/master/PipelinesCore/src/main/java/com/hubspot/objectpipelines/DefaultImmutablePipelines.java
const ticketHubspotStages = {
  1: 'new',
  2: 'waiting on contact',
  3: 'waiting on us',
  4: 'closed'
};
const getDefaultStageLabel = stage => {
  const stageObjectType = stage.objectType;
  switch (stageObjectType) {
    case 'DEAL':
      {
        return dealHubspotStages[stage.stageId];
      }
    case 'TICKET':
      {
        return ticketHubspotStages[stage.stageId];
      }
    case 'COMPANY':
    case 'CONTACT':
      {
        return lifecycleStages[stage.stageId];
      }
    default:
      {
        return null;
      }
  }
};

/**
 * Translates a stage on the default LCS, deal, or ticket pipeline
 * If no valid translation exists, or the label property has been customized, it returns stage.label
 * @param {Object} stage - needs stage.label, stage.stageId, stage.pipelineId, and stage.objectType
 */
const stageLabelTranslator = stage => {
  const defaultPipeline = stage.pipelineId === 'default' || stage.pipelineId === '0' || stage.pipelineId === 'contacts-lifecycle-pipeline' || stage.pipelineId === 'companies-lifecycle-pipeline';
  if (!defaultPipeline) {
    return stage.label;
  }
  const defaultStageLabel = getDefaultStageLabel(stage);
  const currentLabelEqualsDefaultLabel = stage.label.toLowerCase() === defaultStageLabel;
  const translationForLabelExists = isValidI18nKey(`stageLabels.${stage.stageId}`);
  if (currentLabelEqualsDefaultLabel && translationForLabelExists) {
    return I18n.text(`stageLabels.${stage.stageId}`);
  }
  return stage.label;
};
export { isInvalidString, convertToKey, makeNameSpacedKey, makeDescriptionKey, tryLookup, tryYourTranslation, propertyDescriptionTranslator, propertyLabelTranslator, propertyLabelTranslatorWithIsHubSpotDefined, stageLabelTranslator };